import { type Dispatch, type SetStateAction, useMemo } from 'react'
import Box from '@mui/material/Box'
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined'
import HeaderActions from './HeaderActions'
import IconButton from '@mui/material/IconButton'
import InsertPhotoIcon from '@mui/icons-material/InsertPhoto'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemText from '@mui/material/ListItemText'
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf'
import Stack from '@mui/material/Stack'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import useTheme from '@mui/material/styles/useTheme'
// utils
import { fileStorage } from '../../../../utils/apiPath'
// redux
import { selectProfileDetails } from '../../../../redux/selector'
import { dispatch, useSelector } from '../../../../redux/store'
// components
import Image from '../../../../components/Image/Image'
// constants
import { API_URL } from '../../../../constants/envConfig'
// types
//
import { ListStyle } from '../styled'
import { arrRequiredAttachment, attachmentTooltip } from '../model/attachment'
import { Avatar } from '@mui/material'
import { getFileUrl } from '../../../../utils/getFileUrl'
import { setShowFile } from '../../slices/applicant-details'
import PictureAsPdfRoundedIcon from '@mui/icons-material/PictureAsPdfRounded'
import { AttachmentMeta } from '../../../../types/full-form'
type Props = {
  setTab: Dispatch<SetStateAction<number>>
}

export default function AttachDocumentsStep({ setTab }: Readonly<Props>) {
  const theme = useTheme()

  const profileDetails = useSelector(selectProfileDetails)

  const listData = useMemo(() => {
    const labelCount: Record<string, number> = {}
    const result: {
      label: string
      value: string
      infoTip: React.ReactNode
      fileImage: AttachmentMeta
      image: React.ReactElement<any, string | React.JSXElementConstructor<any>>
    }[] = []

    // Count occurrences of each label
    profileDetails?.attachments.forEach((item) => {
      labelCount[item.fieldName] = (labelCount[item.fieldName] || 0) + 1
    })

    // Create the result array with sequence number from duplicates
    const labelSequence: Record<string, number> = {}

    profileDetails?.attachments.forEach((item) => {
      const count = labelCount[item.fieldName]

      let image: React.ReactNode = null

      if (item.fieldName === 'photo') {
        image = (
          <Image
            src={`${API_URL}${fileStorage}${item.url}`}
            alt="Attachment document for application review"
            effect="opacity"
            sx={{ maxWidth: 24, maxHeight: 24, marginLeft: 'auto' }}
          />
        )
      } else if (item.type.startsWith('image/')) {
        image = <InsertPhotoIcon sx={{ color: theme.palette.text.gray }} />
      } else {
        image = <PictureAsPdfIcon sx={{ color: theme.palette.text.gray }} />
      }

      let label = ''

      // If the item appears more then once, assign sequence numbers
      if (count > 1) {
        if (!labelSequence[item.fieldName]) {
          labelSequence[item.fieldName] = 1
        }

        label = `${arrRequiredAttachment[item.fieldName]} (${labelSequence[item.fieldName]})`
        labelSequence[item.fieldName]++
      } else {
        label = arrRequiredAttachment[item.fieldName]
      }

      result.push({
        label,
        value: '-',
        infoTip: (
          <Box width={230}>
            <Typography variant="body2" whiteSpace="balance">
              {attachmentTooltip[item.fieldName]}

              {item.fieldName === 'photo' && (
                <Image
                  src={`${API_URL}${fileStorage}${item.url}`}
                  alt="Profile image"
                  effect="opacity"
                  sx={{ marginLeft: 'auto' }}
                />
              )}
            </Typography>
          </Box>
        ),
        fileImage: item,
        image,
      })
    })

    return result.filter(
      (item) => !item.label?.startsWith('undefined') && item.label
    )
  }, [profileDetails?.attachments])

  return (
    <>
      <Stack spacing={3}>
        {/* HEADER ACTIONS SECTION */}
        <HeaderActions isLastStep titleStep="Attachment" setTab={setTab} />

        {/* LIST ITEM SECTION */}
        <Box>
          {/* LIST ITEM HEADER */}
          <Typography variant="body1b">แนบเอกสาร</Typography>

          <Stack
            minHeight={56}
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            px={2}
          >
            <Typography variant="tableHeader" flex={1}>
              เอกสาร
            </Typography>
          </Stack>

          {/* LIST ITEM CONTENT */}
          <ListStyle
            sx={{ width: '100%', bgcolor: theme.palette.common.white }}
          >
            {listData?.map((item) => (
              <ListItem key={item.label} divider>
                <Stack direction="row" alignItems="center" flex={1} gap={1.5}>
                  <ListItemButton
                    disableTouchRipple
                    disableGutters
                    sx={{ maxWidth: 'fit-content', cursor: 'pointer' }}
                  >
                    {item.fileImage.type === 'image/png' ||
                    item.fileImage.type === 'image/jpeg' ? (
                      <Avatar
                        variant={'square'}
                        src={getFileUrl(item.fileImage.url)}
                        sx={{ mr: 1, borderRadius: 1, cursor: 'pointer' }}
                        onClick={() => {
                          dispatch(
                            setShowFile({ isOpen: true, file: item.fileImage })
                          )
                        }}
                      />
                    ) : (
                      <PictureAsPdfRoundedIcon
                        fontSize={'large'}
                        color="error"
                        sx={{ mr: 2, cursor: 'pointer' }}
                        onClick={() => {
                          dispatch(
                            setShowFile({ isOpen: true, file: item.fileImage })
                          )
                        }}
                      />
                    )}
                  </ListItemButton>
                  <ListItemText
                    primary={item.label}
                    sx={{ maxWidth: 'fit-content' }}
                  />
                  <Tooltip
                    placement="top"
                    arrow
                    title={
                      <Typography color="text.primary">
                        {item.infoTip}
                      </Typography>
                    }
                    componentsProps={{
                      tooltip: {
                        sx: {
                          boxShadow: `0px 0px 4px 0px ${theme.palette.common.black}33`,

                          '&.MuiTooltip-tooltipArrow': {
                            background: theme.palette.background.paper,
                          },

                          '& .MuiTooltip-arrow': {
                            color: theme.palette.background.paper,
                          },
                        },
                      },
                    }}
                  >
                    <IconButton>
                      <ErrorOutlineOutlinedIcon
                        fontSize="small"
                        color="action"
                      />
                    </IconButton>
                  </Tooltip>
                </Stack>
              </ListItem>
            ))}
          </ListStyle>
        </Box>
      </Stack>
    </>
  )
}
