import Typography from '@mui/material/Typography'
import { useEffect, useState } from 'react'
import { dispatch, useSelector } from '../../../redux/store'
import { selectInitialProfile } from '../../../redux/selector'
import Box from '@mui/material/Box'
import { IconButton } from '@mui/material'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import { closeDrawer } from '../../../components/CustomDrawer/slices'

import HistoryTable, {
  DataTableCol,
} from '../../../components/Table/TableHistory'
import { DateFormat } from '../../../constants/dateFormat'
import { convertFormatDateTimeV2 } from '../../../utils/convertFormatDateTime'
import { useHistoryProcess } from './handler/useHistoryProcess'
import { getHistoryProcess } from './FormRequestDecision/handler/getHistoryProcess'
import { historyProcessText } from '../../../constants/dc-admin/history'
import _ from 'lodash'
export const HistoryProcess = () => {
  const initProfile = useSelector(selectInitialProfile)
  const fullFormUuid = String(initProfile?.fullformUuid)
  const [isLoading, setIsLoading] = useState(false)

  const historyAudit = useHistoryProcess()
  useEffect(() => {
    const _init = async () => {
      setIsLoading(true)
      if (fullFormUuid) await dispatch(getHistoryProcess(fullFormUuid))
      setIsLoading(false)
    }
    _init()
  }, [])

  const headers: DataTableCol[] = [
    {
      headerName: 'การดำเนินการ',
      width: '60%',
      field: 'event',
      renderCell: (row: any) => (
        <>{_.get(historyProcessText, `${row.event}`, row.event)}</>
      ),
    },

    {
      headerName: 'ผู้ทำรายการ',
      width: '20%',
      field: 'operator',
    },
    {
      headerName: 'วันที่ทำรายการ',
      width: '20%',
      field: 'createdAt',
      renderCell: (row: any) => (
        <>{convertFormatDateTimeV2(row.createdAt, DateFormat.DDMMYYYYHHmm)}</>
      ),
    },
  ]

  return (
    <Box sx={{ p: 3, width: '100%', maxWidth: '1000px' }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 3 }}>
        <Box sx={{ width: '100%' }}>
          <Typography variant="h5">ประวัติการดำเนินการ</Typography>
        </Box>
        <Box>
          <IconButton onClick={() => dispatch(closeDrawer())}>
            <CloseRoundedIcon />
          </IconButton>
        </Box>
      </Box>
      <HistoryTable
        isLoading={isLoading}
        headers={headers}
        rows={historyAudit.items}
      />
    </Box>
  )
}
