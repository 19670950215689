// /* eslint-disable @typescript-eslint/no-explicit-any */

import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import { useTheme } from '@mui/material'
import ChevronRightOutlined from '@mui/icons-material/ChevronRightOutlined'
import _ from 'lodash'
import { StyledBoxIcon } from './styled'
import { BoxCard } from '../../styled'
import useHistory from '../../../../../../../utils/useHistory'
import { crudConfig } from '../../../../crudConfig'

const CardBox = ({ item, total = 0, isFullWidth = false, children }: any) => {
  const theme = useTheme()
  const icon = _.get(item, 'icon', '')
  const backgroundColor = _.get(
    item,
    'backgroundColor',
    theme.palette?.primary?.main
  )
  const history = useHistory()
  return (
    <BoxCard
      isFullWidth={isFullWidth}
      sx={{ background: theme.palette?.common?.white }}
    >
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        <StyledBoxIcon sx={{ background: backgroundColor }}>
          <img src={icon} />
        </StyledBoxIcon>

        <Box sx={{ width: '100%', display: 'flex', gap: 2, alignItems: 'end' }}>
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              gap: 0.5,
            }}
          >
            <Typography variant="h5" color="primary">
              {_.get(item, 'title', 'ขอแก้ไขเอกสาร')}
            </Typography>
            <Typography color="text.gray">
              {_.get(item, 'subTitle', 'จำนวนผู้สมัครที่ติด Memo')}
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', gap: 1.5, alignItems: 'end' }}>
            <Typography
              color="primary"
              sx={{ fontSize: 56, lineHeight: '40px', fontWeight: 700 }}
            >
              {total}
            </Typography>
            <Typography color="text.gray">
              {_.get(item, 'unit', 'คน')}
            </Typography>
          </Box>
          <IconButton
            sx={{ p: 0, mb: 0.5 }}
            onClick={() =>
              history.push(`${crudConfig.modulePath}?status=${item.status}`)
            }
          >
            <ChevronRightOutlined />
          </IconButton>
        </Box>
      </Box>
      {children && children}
    </BoxCard>
  )
}

export default CardBox
