export const permissionItem = {
  CANDIDATE: 'ผู้สมัคร',
  ASSIGN_RULE: 'Assign Rule',
  DC_ADMIN: 'ตรวจสอบใบสมัคร',
  DASHBOARD: 'Dashboard',
  DASHBOARD_WORKLOAD: 'Dashboard Workload',
  MASTER_DATA: 'Master Data',
  PERMISSION: 'Manage',
}

export const permissionAgencyAdmin = [
  {
    module: 'AGENCY_ADMIN',
    owner: 'ALL',
    permission: ['CANDIDATE'],
    remark: 'สิทธิ์การเข้าถึงฟังชั่นการทำงานของ Agency Admin',
  },
  {
    module: 'AGENCY_ADMIN',
    owner: 'ALL',
    permission: ['ASSIGN_RULE'],
    remark: 'สิทธิ์การเข้าถึงฟังชั่นการทำงานของ Assign Rule',
  },
]

export const permissionSuperAdmin = [
  {
    module: 'SUPER_ADMIN',
    owner: 'ALL',
    permission: ['MASTER_DATA'],
    remark: 'สิทธิ์การเข้าถึงฟังชั่นการทำงานของ Master Data',
  },
  {
    module: 'SUPER_ADMIN',
    owner: 'ALL',
    permission: ['PERMISSION'],
    remark: 'สิทธิ์การเข้าถึงฟังชั่นการทำงานของ Permission',
  },
]

export const permissionDCAdmin = [
  {
    module: 'DC_ADMIN',
    owner: 'ALL',
    permission: ['DC_ADMIN'],
    remark: 'สิทธิ์การเข้าถึงฟังชั่นการทำงานของ DC',
  },
  {
    module: 'DC_ADMIN',
    owner: 'PIC',
    permission: ['DASHBOARD'],
    remark: 'สิทธิ์การ View ภาพรวม',
  },
  {
    module: 'DC_ADMIN',
    owner: 'ALL',
    permission: ['DASHBOARD_WORKLOAD'],
    remark: 'สิทธิ์การ View รายการ',
  },
]
