// /* eslint-disable @typescript-eslint/no-explicit-any */

import { useEffect } from 'react'
import { StyledContainer } from '../../../../../components/Styled'
import DashboardContent from './components/DashboardContent'
import DCAdminTable from './components/DCAdminTable'
import { fetchData } from './handler/fetchData'
import Header from './Header'
import { useSelector } from '../../../../../redux/store'
import { selectFilterProp } from '../../../../../redux/selector'
import LoadingPageCircular from '../../../../../components/CRUD/components/LoadingPageCircular'
import { checkPermission } from '../../../../../utils/checkPermission'

const Dashboard = () => {
  const isLoading = useSelector((state) => state.dcAdmin.dashboard.isLoading)
  const filterProp = useSelector(selectFilterProp)
  const hasWorkloadPermission = checkPermission({
    module: 'DC_ADMIN',
    permission: 'DASHBOARD_WORKLOAD',
  })

  useEffect(() => {
    fetchData()
  }, [filterProp])

  return (
    <StyledContainer sx={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
      <Header />

      <DashboardContent />

      {hasWorkloadPermission && <DCAdminTable />}

      <LoadingPageCircular isLoading={isLoading} />
    </StyledContainer>
  )
}

export default Dashboard
