import { Box, IconButton, Typography } from '@mui/material'
import { CustomLoadingButton } from '../../../../components/Input/CustomButton'
import { closeCustomDialog } from '../../../../components/CustomDialog/slices'
import { dispatch, useSelector } from '../../../../redux/store'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import { FormRAM } from './FormRAM'
import { FormCADTO } from './FormCADTO'
import { FormView } from './FormView'
import { callSubmit } from './services/thunk'
import _ from 'lodash'
import {
  selectInitialProfile,
  selectCrudFormData,
  selectCrudLoading,
} from '../../../../redux/selector'
import { Complete } from './Complete'
import { setFormIsLoading } from '../../../../components/CRUD/slices'
import { ApiStatus } from '../../../../constants/status'
import { getProfileDetails } from '../../slices/applicant-details'
import { useState } from 'react'
import { getTitleNotOwner } from '../handler/getTitleNotOwner'

export const FormRequestDecision = () => {
  const [actionStep, setActionStep] = useState('RAM')
  const initProfile = useSelector(selectInitialProfile)
  const formData = useSelector(selectCrudFormData)
  const isLoading = useSelector(selectCrudLoading)
  const fullFormUuid = String(initProfile?.fullformUuid)
  const { decision } = formData
  const handleSubmitDecision = async () => {
    const metaIds = _.map(decision.attachments ?? [], 'id')
    dispatch(setFormIsLoading(true))
    const { payload } = await dispatch(
      callSubmit({
        fullFormUuid: fullFormUuid,
        hasSendCCM: decision.hasSendCCM === 'YES' ? true : false,

        hasSendRam: !_.isEmpty(decision.RamUuid) ? true : false,
        RamUuid: decision.RamUuid,

        hasSendSRam: !_.isEmpty(decision.SRamUuid) ? true : false,
        SRamUuid: decision.SRamUuid,

        hasSendCADTO: !_.isEmpty(decision.CADTOUuid) ? true : false,
        CADTOUuid: decision.CADTOUuid,

        hasSendCDO: !_.isEmpty(decision.CDOUuid) ? true : false,
        CDOUuid: decision.CDOUuid,

        detail: decision.detail,
        metaId: metaIds,
      })
    )
    if (payload?.status !== ApiStatus.ERROR) {
      setActionStep('COMPLETE')
    }
    dispatch(setFormIsLoading(false))
  }

  const getButton = () => {
    if (actionStep === 'CADTO') {
      return (
        <>
          <CustomLoadingButton
            sx={{ borderRadius: 1 }}
            variant={'outlined'}
            color="info"
            onClick={() => setActionStep('RAM')}
          >
            ย้อนกลับ
          </CustomLoadingButton>
          <CustomLoadingButton
            sx={{ borderRadius: 1 }}
            variant="contained"
            color="info"
            onClick={() => setActionStep('REVIEW')}
          >
            ถัดไป
          </CustomLoadingButton>
        </>
      )
    } else if (actionStep === 'REVIEW') {
      return (
        <>
          <CustomLoadingButton
            sx={{ borderRadius: 1 }}
            variant={'outlined'}
            color="info"
            onClick={() => setActionStep('CADTO')}
          >
            ย้อนกลับ
          </CustomLoadingButton>
          <CustomLoadingButton
            sx={{ borderRadius: 1 }}
            variant="contained"
            color="info"
            loading={isLoading}
            onClick={() => handleSubmitDecision()}
          >
            ยืนยัน
          </CustomLoadingButton>
        </>
      )
    } else if (actionStep === 'COMPLETE') {
      return (
        <>
          <CustomLoadingButton
            sx={{ borderRadius: 1 }}
            variant="contained"
            color="info"
            onClick={() => {
              dispatch(
                getProfileDetails({ prospectId: String(initProfile?.uuid) })
              )
              dispatch(closeCustomDialog())
            }}
          >
            รับทราบ
          </CustomLoadingButton>
        </>
      )
    } else {
      return (
        <>
          <CustomLoadingButton
            sx={{ borderRadius: 1 }}
            variant={'outlined'}
            color="info"
            onClick={() => dispatch(closeCustomDialog())}
          >
            ย้อนกลับ
          </CustomLoadingButton>
          <CustomLoadingButton
            sx={{ borderRadius: 1 }}
            variant="contained"
            color="info"
            disabled={_.isEmpty(decision?.hasSendCCM)}
            onClick={() => setActionStep('CADTO')}
          >
            ถัดไป
          </CustomLoadingButton>
        </>
      )
    }
  }
  const getContent = () => {
    if (actionStep === 'CADTO') {
      return <FormCADTO />
    } else if (actionStep === 'REVIEW') {
      return <FormView />
    } else if (actionStep === 'COMPLETE') {
      return <Complete />
    } else {
      return <FormRAM />
    }
  }

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          mb: 2,
          justifyContent: 'space-between',
          alignItems: 'center',
          pr: 2,
        }}
      >
        <Typography variant="h6" sx={{ width: '100%' }}>
          {actionStep === 'COMPLETE' ? (
            <>สำเร็จ</>
          ) : (
            <>
              {actionStep === 'REVIEW' &&
                getTitleNotOwner(initProfile, 'ยืนยัน')}{' '}
              {' ส่งพิจารณาเพิ่มเติม'}
            </>
          )}
        </Typography>
        {actionStep === 'REVIEW' && (
          <IconButton onClick={() => setActionStep('RAM')}>
            <EditOutlinedIcon />
          </IconButton>
        )}
      </Box>

      <Box
        id={'FormRequestDecisionContent'}
        sx={{
          maxHeight: '65vh',
          overflow: 'auto',
          scrollbarWidth: 'thin',
          pr: 3,
        }}
      >
        {getContent()}
      </Box>

      <Box
        sx={{ display: 'flex', justifyContent: 'end', gap: 1, mt: 3, pr: 3 }}
      >
        {getButton()}
      </Box>
    </>
  )
}
