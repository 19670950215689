import isEmpty from 'lodash/isEmpty'
import Modal from 'react-modal'
import { useCallback, useState } from 'react'
import useTheme from '@mui/material/styles/useTheme'
import LoadingButton from '@mui/lab/LoadingButton'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
// redux
import { dispatch, useDispatch, useSelector } from '../../../../redux/store'
import {
  selectProgressStatus,
  selectCrudFormData,
  selectInitialProfile,
} from '../../../../redux/selector'
import {
  applicationStatusStep,
  submitLicenseAgent,
  submitRejectByIOC,
} from '../../slices/applicant-details'
// hooks
import useGetMessageOwnerDialog from '../../../../hooks/useGetMessageOwnerDialog'
// components
import Image from '../../../../components/Image/Image'
import { closeDialog, openDialog } from '../../../../components/dialog/slices'
import { CustomDateInput } from '../../../../components/CRUD/components/CustomDateInput'
//
import { CardContentStyle, CardStyle } from '../styled'
import { getFormatDate } from '../../../../utils/getFormatDate'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import { CustomInput } from '../../../../components/CRUD/components/CustomInput'
import {
  closeCustomDialog,
  openCustomDialog,
} from '../../../../components/CustomDialog/slices'
import { callLicenseAgent } from '../services/thunk'
import { DIALOG_TYPE_STRING } from '../../../../components/dialog/constants'
import { ApiStatus } from '../../../../constants/status'
import { CustomLoadingButton } from '../../../../components/Input/CustomButton'
import _ from 'lodash'
import CustomTextfield from '../../../../components/Input/CustomTextfield'
import { getTitleNotOwner } from '../handler/getTitleNotOwner'

Modal.setAppElement('#root')

export default function AwaitingLicenseNoStatus() {
  const [isOpenModal, setIsOpenModal] = useState(false)

  const initProfile = useSelector(selectInitialProfile)
  const applicationStatus = useSelector(selectProgressStatus)
  const formData = useSelector(selectCrudFormData) as {
    licenseNumber: string
    startLicenseDate: string
    expireLicenseDate: string
  }
  const title = getTitleNotOwner(initProfile, 'กรอกเลขใบอนุญาต')

  const messageDialog = useGetMessageOwnerDialog(
    'ไม่อนุมัติจาก คปภ.',
    'ไม่ผ่านการอนุมัติจาก คปภ.'
  )

  const dispatch = useDispatch()

  const theme = useTheme()

  const openModal = () => {
    setIsOpenModal(true)

    document.body.style.overflow = 'hidden'
  }

  const closeModal = () => {
    setIsOpenModal(false)

    document.body.style.overflow = 'auto'
  }

  const onRejectByIOC = () => {
    dispatch(
      openDialog({
        type: '',
        ...messageDialog,
        handleConfirm() {
          dispatch(submitRejectByIOC(String(initProfile?.fullformUuid)))
          dispatch(closeDialog())
          closeModal()
        },
        isCloseDialog: false,
      })
    )
  }

  const handleLicenseNo = useCallback(() => {
    const { licenseNumber, startLicenseDate, expireLicenseDate } = formData

    dispatch(
      submitLicenseAgent({
        fullFormUuid: String(initProfile?.fullformUuid),
        licenseNumber,
        startLicenseDate,
        expireLicenseDate,
      })
    )

    closeModal()
  }, [dispatch, formData, initProfile?.fullformUuid])

  return (
    <>
      <CardStyle>
        <CardContentStyle>
          <Stack direction="row" gap={2}>
            <Image
              src="/images/illustrate_awaiting_license_no.svg"
              alt="Awaiting license number process"
              effect="opacity"
              sizes="56"
              sx={{
                '& .MuiBox-root': {
                  verticalAlign: 'middle',
                  objectFit: 'contain',
                },
              }}
            />

            <Stack flex={1} justifyContent="center" gap={0.5}>
              <Typography variant="h5" color="primary.main" whiteSpace="nowrap">
                {applicationStatus && applicationStatusStep[applicationStatus]}
              </Typography>
              <Typography variant="body2" color="text.gray">
                วันที่: {getFormatDate(String(initProfile?.statsuUpdateDate))}
              </Typography>
            </Stack>

            <Stack justifyContent="flex-end" gap={1}>
              <Button
                variant="outlined"
                size="small"
                endIcon={<ArrowForwardIcon fontSize="small" />}
                sx={{ marginButton: 1 }}
                onClick={() =>
                  dispatch(
                    openCustomDialog({
                      type: DIALOG_TYPE_STRING.CUSTOM,
                      title: `${title}`,
                      content: <AwaitingLicenseNoStatusForm />,
                    })
                  )
                }
              >
                กรอกเลขใบอนุญาต
              </Button>

              <Button variant="outlined" size="small" onClick={onRejectByIOC}>
                ไม่ผ่านการอนุมัติจาก คปภ.
              </Button>
            </Stack>
          </Stack>
        </CardContentStyle>
      </CardStyle>

      {/* MODAL */}
      <Modal
        isOpen={isOpenModal}
        onRequestClose={closeModal}
        contentLabel="Awaiting license number modal"
        style={{
          overlay: {
            backgroundColor: `${theme.palette.common.black}BF`,
            zIndex: 1200, // For ensuring overlay is above other elements
          },
          content: {
            maxWidth: '678px',
            width: '100%',
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            padding: 0,
            backgroundColor: theme.palette.background.paper,
            borderRadius: '8px',
            boxShadow: `0px 9px 46px 8px ${theme.palette.common.black}1F,
                          0px 24px 38px 3px ${theme.palette.common.black}24,
                          0px 11px 15px -7px ${theme.palette.common.black}33`,
          },
        }}
      >
        <Box padding={2}>
          <Typography variant="h6">กรอกเลขใบอนุญาต</Typography>
        </Box>
        <Stack padding={2} pt={2} gap={2}>
          <CustomInput
            required
            labelName="เลขที่ใบอนุญาต"
            fieldName="licenseNumber"
            placeholder="เลขที่ใบอนุญาต"
          />
          <Stack direction="row" alignItems="center" gap={2}>
            <CustomDateInput
              required
              labelName="วันออกใบอนุญาต"
              fieldName="startLicenseDate"
              placeholder="วว/ดด/ปปปปป"
              sxTextError={{ mb: 0 }}
            />

            <CustomDateInput
              required
              labelName="วันใบอนุญาตหมดอายุ"
              fieldName="expireLicenseDate"
              placeholder="วว/ดด/ปปปปป"
              sxTextError={{ mb: 0 }}
            />
          </Stack>
          <Stack direction="row" spacing={1.5} marginLeft="auto" paddingY={1}>
            <Button variant="outlined" onClick={closeModal}>
              ยกเลิก
            </Button>
            <LoadingButton
              variant="contained"
              onClick={handleLicenseNo}
              disabled={
                isEmpty(formData?.licenseNumber) ||
                isEmpty(formData?.startLicenseDate) ||
                isEmpty(formData?.expireLicenseDate)
              }
            >
              บันทึก
            </LoadingButton>
          </Stack>
        </Stack>
      </Modal>
    </>
  )
}
export const AwaitingLicenseNoStatusForm = () => {
  const [isLoading, setIsLoading] = useState(false)
  const initProfile = useSelector(selectInitialProfile)
  const formData = useSelector(selectCrudFormData) as {
    licenseNumber: string
    startLicenseDate: string
    expireLicenseDate: string
  }
  const fullFormUuid = String(initProfile?.fullformUuid)

  const handleSubmitLicenseNo = async () => {
    const { licenseNumber, startLicenseDate, expireLicenseDate } = formData

    setIsLoading(true)
    const { payload } = await dispatch(
      callLicenseAgent({
        fullFormUuid: fullFormUuid,
        licenseNumber,
        startLicenseDate,
        expireLicenseDate,
      })
    )
    setIsLoading(false)
    if (payload?.status && payload?.status !== ApiStatus.ERROR) {
      dispatch(
        openCustomDialog({
          type: DIALOG_TYPE_STRING.SUCCESS,
          title: 'สำเร็จ',
          message: 'บันทึกเลขใบอนุญาตเรียบร้อยแล้ว',
          handleOK: () => {
            window.location.reload()
          },
        })
      )
    }
  }
  return (
    <>
      <Stack pt={2} gap={2}>
        <CustomTextfield
          required
          label="เลขที่ใบอนุญาต"
          crud="licenseNumber"
          placeholder="เลขที่ใบอนุญาต"
          maxLength={10}
          valueType="number"
          fullWidth
        />
        <Stack direction="row" alignItems="center" gap={2}>
          <CustomDateInput
            required
            labelName="วันออกใบอนุญาต"
            fieldName="startLicenseDate"
            placeholder="วว/ดด/ปปปปป"
            sxTextError={{ mb: 0 }}
          />

          <CustomDateInput
            required
            labelName="วันใบอนุญาตหมดอายุ"
            fieldName="expireLicenseDate"
            placeholder="วว/ดด/ปปปปป"
            sxTextError={{ mb: 0 }}
          />
        </Stack>
        <Stack direction="row" spacing={1.5} marginLeft="auto" paddingY={1}>
          <Button
            variant="outlined"
            onClick={() => dispatch(closeCustomDialog())}
          >
            ยกเลิก
          </Button>
          <CustomLoadingButton
            loading={isLoading}
            disabled={
              isEmpty(formData?.licenseNumber) ||
              isEmpty(formData?.startLicenseDate) ||
              isEmpty(formData?.expireLicenseDate) ||
              _.size(formData?.licenseNumber) !== 10
            }
            onClick={handleSubmitLicenseNo}
          >
            บันทึก
          </CustomLoadingButton>
        </Stack>
      </Stack>
    </>
  )
}
