import { type Dispatch, type SetStateAction, useEffect } from 'react'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
// utils
import { handleCalAge, getFormatDate } from '../../../../utils/getFormatDate'
// redux
import { useDispatch, useSelector } from '../../../../redux/store'
import { selectProfileDetails } from '../../../../redux/selector'
//
import ListItem from './ListItem'
import HeaderActions from './HeaderActions'
import {
  getLabelFullFormArrText,
  FULLFORM,
} from '../../../../constants/dc-admin'
import { formatAgentCode } from '../../../../utils/formatAgentCode'
import { SliceModel } from '../../../../redux/models'
import { getProvinceList } from '../../../../components/Lookup/ManagerLookup/service'

type Props = {
  setTab: Dispatch<SetStateAction<number>>
}

export default function PersonalInfoStep({ setTab }: Readonly<Props>) {
  const dispatch = useDispatch()
  const profileDetails = useSelector(selectProfileDetails)
  const provinces = useSelector((state: SliceModel) => state.province.list)

  useEffect(() => {
    if (provinces.length === 0) {
      dispatch(getProvinceList())
    }
  }, [provinces, dispatch])

  if (!profileDetails) {
    return null
  }

  const findProvinceNameByUuid = (uuid: string) => {
    const province = provinces.find((item) => item.uuid === uuid)
    return province ? province.provinceName : 'ไม่พบจังหวัด'
  }

  return (
    <Stack spacing={3}>
      {/* HEADER ACTION SECTION */}
      <HeaderActions titleStep="Personal" setTab={setTab} />

      {/* LIST ITEM SECTION */}
      <Box>
        {/* LIST ITEM HEADER */}
        <Typography variant="body1b" color="text.primary">
          รหัสผู้จัดการ
        </Typography>
        <Stack
          minHeight={56}
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography variant="tableHeader" flex={1} pl={2}>
            หัวข้อ
          </Typography>
          <Typography variant="tableHeader" flex={1}>
            ข้อมูลที่กรอก
          </Typography>
        </Stack>

        {/* LIST ITEM CONTENT */}

        {/* รหัสผู้จัดการ section */}
        <ListItem
          listData={[
            {
              label: 'รหัสผู้จัดการ',
              value: profileDetails.manager.userCode
                ? formatAgentCode(profileDetails.manager.userCode)
                : '-',
            },
            {
              label: 'ชื่อ นามสกุลผู้จัดการ',
              value:
                `${profileDetails.manager.firstNameTH} ${profileDetails.manager.lastNameTH}` ??
                '-',
            },
            {
              label: 'รหัสตัวแทนผู้ชักชวน',
              value: profileDetails.main.invitedCode
                ? formatAgentCode(profileDetails.main.invitedCode)
                : '-',
            },
          ]}
        />

        <ListItem
          subheader={
            <Typography variant="body1b" color="text.primary" my={2}>
              ข้อมูลส่วนบุคคล
            </Typography>
          }
          listData={[
            {
              label: 'คำนำหน้า',
              value:
                getLabelFullFormArrText(
                  FULLFORM.title,
                  profileDetails.personal.title
                ) ?? '-',
            },
            {
              label: 'ชื่อ',
              value: profileDetails.personal.firstName ?? '-',
            },
            {
              label: 'นามสกุล',
              value: profileDetails.personal.lastName ?? '-',
            },
            {
              label: 'ชื่อภาษาอังกฤษ',
              value: profileDetails.personal.firstNameEn ?? '-',
            },
            {
              label: 'นามสกุลภาษาอังกฤษ',
              value: profileDetails.personal.lastNameEn ?? '-',
            },
            {
              label: 'เพศ',
              value:
                getLabelFullFormArrText(
                  FULLFORM.gender,
                  profileDetails.personal.gender
                ) ?? '-',
            },
            {
              label: 'อายุ',
              value: handleCalAge(profileDetails.personal.dateOfBirth) ?? '-',
            },
            {
              label: 'ฐานะการสมรส',
              value: getLabelFullFormArrText(
                FULLFORM.maritalStatus,
                profileDetails.personal.maritalStatus
              ),
            },
            {
              label: 'คำนำหน้า คู่สมรส',
              value:
                getLabelFullFormArrText(
                  FULLFORM.title,
                  profileDetails?.personalSpouse?.title
                ) ?? '-',
            },
            {
              label: 'ชื่อ นามสกุล คู่สมรส',
              value: profileDetails.personalSpouse.fullName ?? '-',
            },
            {
              label: 'วัน/เดือน/ปี เกิด คู่สมรส',
              value: profileDetails?.personalSpouse?.dateOfBirth
                ? getFormatDate(profileDetails?.personalSpouse?.dateOfBirth)
                : '-',
            },
            {
              label: 'เลขบัตรประชาชน คู่สมรส',
              value: profileDetails.personal?.idCard ?? '-',
            },
            {
              label: 'สัญชาติิ',
              value:
                getLabelFullFormArrText(
                  FULLFORM.nationality,
                  profileDetails.personal.nationality
                ) ?? '-',
            },
            {
              label: 'สัญชาติอื่น ๆ',
              value: profileDetails.personal.nationalityOther ?? '-',
            },
            {
              label: 'เชื้อชาติ',
              value: profileDetails.personal.ethnicity === 'YES' ? 'ไทย' : '-',
            },
          ]}
        />

        <ListItem
          subheader={
            <Typography variant="body1b" color="text.primary" my={2}>
              ที่อยู่อาศัย
            </Typography>
          }
          listData={[
            {
              label: 'บ้านเลขที่',
              value: profileDetails.personalAddress.houseNo ?? '-',
            },
            {
              label: 'หมู่ที่',
              value: profileDetails.personalAddress.village ?? '-',
            },
            {
              label: 'ตรอก/ซอย',
              value: profileDetails.personalAddress.soi ?? '-',
            },
            {
              label: 'ถนน',
              value: profileDetails.personalAddress.road ?? '-',
            },
            {
              label: 'จังหวัด',
              value:
                findProvinceNameByUuid(
                  profileDetails.personalAddress.provinceUuid
                ) ?? '-',
            },
            {
              label: 'เขต/อำเภอ',
              value:
                profileDetails.personalAddress.amphurUuidData.amphureName ??
                '-',
            },
            {
              label: 'แขวง/ตำบล',
              value:
                profileDetails.personalAddress.tambonUuidData.tambonName ?? '-',
            },
            {
              label: 'รหัสไปรษณีย์',
              value: profileDetails.personalAddress.zipcode ?? '-',
            },
            {
              label: 'ประเภทที่อยู่อาศัย',
              value:
                getLabelFullFormArrText(
                  FULLFORM.addressType,
                  profileDetails.personalAddress.addressType
                ) ?? '-',
            },
          ]}
        />

        <ListItem
          subheader={
            <Typography variant="body1b" color="text.primary" my={2}>
              เบอร์โทรศัพท์ / อีเมล
            </Typography>
          }
          listData={[
            {
              label: 'โทรศัพท์มือถือ',
              value: profileDetails.personal.mobileNo ?? '-',
            },
            {
              label: 'อีเมล',
              value: profileDetails.personal.email ?? '-',
            },
            {
              label: 'เบอร์โทรศัพท์ บ้าน/สำนักงาน',
              value: profileDetails.personal.homePhone ?? '-',
            },
          ]}
        />

        <ListItem
          subheader={
            <Typography variant="body1b" color="text.primary" my={2}>
              บัตรประจำตัวประชาชน / บัตรอื่น ๆ
            </Typography>
          }
          listData={[
            {
              label: 'เลขประจำตัวประชาชน',
              value: profileDetails.personal.idCard ?? '-',
            },
            {
              label: 'บัตรอื่น ๆ ',
              value: '',
            },
            {
              label: 'ชื่อบัตร',
              value: profileDetails.personal.otherCardName ?? '-',
            },
            {
              label: 'เลขบัตร',
              value: profileDetails.personal.otherIdCard ?? '-',
            },
          ]}
        />
      </Box>
    </Stack>
  )
}
