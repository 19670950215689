import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { Dayjs } from 'dayjs'
import { useDispatch } from 'react-redux'
import { setReceiveDate } from '../../../slices/receive-payment'

export default function ReceiveDate() {
  const dispatch = useDispatch()

  const handleDateChange = (newValue: Dayjs | null) => {
    dispatch(setReceiveDate(newValue))
  }

  return (
    <div style={{ width: '100%' }}>
      <Box sx={{ display: 'flex', flexDirection: 'row', mb: 1 }}>
        <Typography variant={'body2'} color={'text.secondary'}>
          วันที่รับเงิน
        </Typography>

        <Typography sx={{ lineHeight: 1, ml: 0.5 }} color={'error'}>
          *
        </Typography>
      </Box>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          onChange={handleDateChange}
          label=""
          slotProps={{
            textField: {
              placeholder: 'เลือกวันที่',
              error: false,
              sx: {
                width: '100%',
                '& .Mui-error .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#C91432 !important',
                },
              },
            },
          }}
        />
      </LocalizationProvider>
    </div>
  )
}
