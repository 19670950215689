import _ from 'lodash'
import { fetchDataList } from '../../../../../../components/CRUD/handler/fetchDataList'
import {
  setDownload,
  setMoreMenu,
  setSearchPlaceholder,
} from '../../../../../../components/Table/redux'
import { store } from '../../../../../../redux/store'
import { getQueryParams } from '../../../../../../utils/getQueryParams'
import { moreMenu } from '../model/moreMenu'
import { mutateFilterPropToBody } from '../mutation/filterPropToBody'
import { handleDownload } from './handleDownload'
import { handleMoreMenuClick } from './handleMoreMenuClick'

export const handleFetchData = async () => {
  await fetchDataList(mutateFilterPropToBody)
  const status = getQueryParams('status')
  const menu = _.get(moreMenu, status, []).map((item) => ({
    label: _.get(item, 'label', ''),
    onClick: () => handleMoreMenuClick(_.get(item, 'value', '')),
  }))
  store.dispatch(setMoreMenu(menu))
  store.dispatch(setDownload(handleDownload))
  store.dispatch(setSearchPlaceholder('ค้นหาชื่อผู้สมัคร'))
}
