import { Typography } from '@mui/material'
import { selectCrudFormData } from '../../../../redux/selector'
import { useSelector } from 'react-redux'
import _ from 'lodash'

export const Complete = () => {
  const formData = useSelector(selectCrudFormData)
  const { decision } = formData

  const arrText = []
  if (!_.isEmpty(decision.hasSendCCM)) {
    arrText.push('CCM')
  }
  if (!_.isEmpty(decision.RamUuid)) {
    arrText.push('RAM')
  }
  if (!_.isEmpty(decision.CADTOUuid)) {
    arrText.push('CADTO')
  }
  if (!_.isEmpty(decision.CDOUuid)) {
    arrText.push('CDO')
  }
  return (
    <>
      <Typography>อยู่ในรายการรอส่งอีเมลหา {arrText.join(',')} แล้ว</Typography>
    </>
  )
}
