import React from 'react'
import { AppBar, Toolbar, Box } from '@mui/material'
import useTheme from '@mui/system/useTheme'
import { deleteFromStorage } from '@rehooks/local-storage'
import { IconButton, Divider, Button, Menu, MenuItem } from '@mui/material'
import { Close, Menu as MenuIcon } from '@mui/icons-material'
import { AuthContextProps } from 'react-oidc-context'
import { useAuth } from 'react-oidc-context'
import NotificationView from '../../modules/Notification/NotificationView'

interface AppbarProps {
  open: boolean
  setOpen: (open: boolean) => void
}

export const handleLogout = (
  keycloak: AuthContextProps | undefined,
  expire = false
) => {
  deleteFromStorage('user')
  deleteFromStorage('token')
  deleteFromStorage('refresh_token')
  deleteFromStorage('authUser')

  console.log('expire ->', expire)
  if (expire) {
    if (window.__env__.ENV === 'DEV') {
      keycloak?.signinRedirect({
        redirect_uri: `${window.__env__.REACT_APP_APP_URL}`,
      })
    } else {
      keycloak?.signinRedirect({
        redirect_uri: `${window.__env__.REACT_APP_APP_URL}`,
      })
    }
  } else {
    if (window.__env__.ENV === 'DEV') {
      keycloak?.signoutRedirect({
        post_logout_redirect_uri: window.__env__.REACT_APP_LOGOUT_URL,
      })
    } else {
      const url_logout = `${window.__env__.ONE_LOGOUT_URL}`
      const wt = window.open(
        url_logout,
        'staff',
        'width=10,height=1,left=5,top=3'
      )
      setTimeout(() => {
        wt?.close()
        keycloak?.signoutRedirect({
          post_logout_redirect_uri: `${window.__env__.REACT_APP_APP_URL}`,
        })
      }, 900)
      return false
    }
  }
}

export const handleClick = (event: any, setAnchorEl: any) => {
  setAnchorEl(event.currentTarget)
}

export const handleClose = (setAnchorEl: any) => {
  setAnchorEl(null)
}

// export const handleProfile = (user : any, history : any) => {
//     if (_.get(user, 'uuid', '') !== '')
//       history.push('/manage/staff-profile/' + user.uuid)
// }

export const handleChange = (open: boolean, setOpen: any) => {
  setOpen(!open)
}

export default function Appbar({ open, setOpen }: AppbarProps) {
  const theme = useTheme()
  const auth = useAuth()
  // const history = useHistory()
  // const [user] = useLocalStorage('user')
  const [anchorEl, setAnchorEl] = React.useState(null)
  const isOpen = Boolean(anchorEl)
  // const isHaveImage = _.get(user, 'image', '') !== ''

  return (
    <Box>
      <AppBar
        sx={{
          backgroundColor: theme?.palette?.primary?.contrast,
          boxShadow: theme?.palette?.shadow?.main,
          zIndex: 1198,
        }}
      >
        <Toolbar sx={{ boxShadow: theme?.palette?.boxShadow?.menu }}>
          <Box
            sx={{
              display: 'flex',
              [theme.breakpoints.up('md')]: {
                display: 'none',
              },
            }}
          >
            <IconButton
              sx={{ mr: 2 }}
              data-testid="btn-drawer-menu"
              onClick={() => handleChange(open, setOpen)}
            >
              {open ? <Close color="primary" /> : <MenuIcon color="primary" />}
            </IconButton>
            <Divider orientation="vertical" flexItem />
            <Box sx={{ pt: 1.5, pl: 1.5 }}>
              <img src={'/logo/axa.png'} alt="DMS" height="48px" />
            </Box>
          </Box>

          <Box sx={{ display: 'flex', position: 'absolute', right: 32 }}>
            <NotificationView />
            <Button
              sx={{
                lineHeight: '34px',
                gap: 1,
                minWidth: 24,
                textTransform: 'none',
              }}
              id="img-profile-btn"
              aria-controls={open ? 'basic-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              onClick={(event) => handleClick(event, setAnchorEl)}
            >
              {auth?.user?.profile.email}
            </Button>
            <Menu
              data-testid="img-profile-menu"
              id="basic-menu"
              anchorEl={anchorEl}
              open={isOpen}
              onClose={() => handleClose(setAnchorEl)}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
            >
              <MenuItem
                data-testid="logout-menu"
                onClick={() => handleLogout(auth)}
              >
                Logout
              </MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </AppBar>
    </Box>
  )
}
