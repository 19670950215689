import { useMemo, useState } from 'react'
import Button from '@mui/material/Button'
import Popover from '@mui/material/Popover'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import Drawer from '@mui/material/Drawer'
import CloseIcon from '@mui/icons-material/Close'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import useTheme from '@mui/material/styles/useTheme'
import styled from '@mui/material/styles/styled'
import InsertPhotoIcon from '@mui/icons-material/InsertPhoto'
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf'
import DeleteTwoTone from '@mui/icons-material/DeleteTwoTone'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import InboxTwoToneIcon from '@mui/icons-material/InboxTwoTone'
import { dispatch, useDispatch, useSelector } from '../../../redux/store'
import { deleteMemoItem, setShowFile } from '../slices/applicant-details'
import {
  selectMemoList,
  selectInitialProfile,
  selectProfileDetails,
} from '../../../redux/selector'
// utils
import { getFormatDate } from '../../../utils/getFormatDate'
//
import { TabApplicationReview } from './model/tab'
import {
  auditHistoryHeader,
  additionalSubmissionHistoryHeader,
  editHistoryHeader,
  memoHeader,
} from './model/table'
import { getObjectValue } from '../../../utils'
import { ListStyle } from './styled'
import { Avatar } from '@mui/material'
import { getFileUrl } from '../../../utils/getFileUrl'
import { getLabelFile } from './handler/getLabelFile'
import PictureAsPdfRoundedIcon from '@mui/icons-material/PictureAsPdfRounded'
import _ from 'lodash'
import {
  openCustomDialog,
  closeCustomDialog,
  setCustomDialogIsLoading,
} from '../../../components/CustomDialog/slices'
import { DIALOG_TYPE_STRING } from '../../../components/dialog/constants'
import { ApiStatus } from '../../../constants/status'
import { callRequestMemo } from './services/thunk'
import { getTitleNotOwner } from './handler/getTitleNotOwner'

type Props = {
  open: boolean
  toggleDrawer: (newOpen: boolean) => () => void
}

const TableRowStyle = styled(TableRow)(({ theme }) => ({
  '&.MuiTableRow-root:nth-of-type(odd)': {
    backgroundColor: theme.palette.background.blue,
  },

  '& .MuiTableCell-root': {
    borderBottom: `${theme.palette.common.black}14`,
  },
}))

const HeaderStyle = styled(Stack)(({ theme }) => ({
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: theme.spacing(2.5, 3),
  boxShadow: `0px -1px 0px 0px ${theme.palette.silver.primary}40 inset`,
}))

export function AuditHistoryDrawer({ open, toggleDrawer }: Readonly<Props>) {
  const theme = useTheme()

  const [anchorEl, setAnchorEl] = useState<HTMLTableCellElement | null>(null)
  const [expandedIndex, setExpandedIndex] = useState<null | number>(null)

  const handlePopoverClick =
    (event: React.MouseEvent<HTMLTableCellElement>) => (index: number) => {
      setAnchorEl(event.currentTarget)
      setExpandedIndex(index)
    }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const openPopover = Boolean(anchorEl)
  const id = openPopover ? 'audit-history-details' : undefined

  const listData = useMemo(() => {
    return [
      {
        titleStep: 'แนบเอกสาร',
        reason: 'ถูกปฏิเสธโดยระบบ',
        details: 'สมัครงานคัดเลือกประเมินปฏิบัติการยุทธศาสตร์ธุรกิจ',
        dataAccuracy: 'ออก MEMO',
        auditor: 'แอดมิน A',
        createdAt: '2024-09-12 08:58:38.595593',
      },
      {
        titleStep: 'นโยบาย',
        reason: 'ข้อมูลส่วนตัวไม่ถูกต้อง',
        details: 'สมัครงานคัดเลือกประเมินปฏิบัติการยุทธศาสตร์ธุรกิจ',
        dataAccuracy: 'ออก MEMO',
        auditor: 'แอดมิน A',
        createdAt: '2024-01-12 08:58:38.595593',
      },
      {
        titleStep: 'รายการลดหย่อนเพื่อการคำนวณภาษีเงินได้บุคคลธรรมดา',
        reason: 'ข้อมูลส่วนตัวไม่ถูกต้อง',
        details: 'สมัครงานคัดเลือกประเมินปฏิบัติการยุทธศาสตร์ธุรกิจ',
        dataAccuracy: 'ออก MEMO',
        auditor: 'แอดมิน A',
        createdAt: '2024-06-12 08:58:38.595593',
      },
      {
        titleStep: 'ประวัติส่วนตัว',
        reason: '-',
        details: '-',
        dataAccuracy: 'ข้อมูลถูกต้อง',
        auditor: 'แอดมิน B',
        createdAt: '2024-10-12 08:58:38.595593',
      },
    ]
  }, [])

  return (
    <Drawer
      open={open}
      onClose={toggleDrawer(false)}
      anchor="right"
      sx={{
        '& .MuiPaper-root': { maxWidth: 1150, width: '100%' },
      }}
    >
      <Box boxShadow={`-4px 4px 4px 0px ${theme.palette.silver.primary}1A`}>
        {/* HEADER DRAWER */}
        <HeaderStyle>
          <Typography variant="h5">ประวัติการตรวจสอบ</Typography>

          {/* Close drawer button */}
          <IconButton onClick={toggleDrawer(false)}>
            <CloseIcon sx={{ color: theme.palette.primary.dark }} />
          </IconButton>
        </HeaderStyle>

        <Box paddingX={3}>
          <Typography variant="h5" mt={3} mb={2}>
            ผู้ตรวจ
          </Typography>

          {/* Table */}
          <TableContainer
            component={Paper}
            sx={{ '&.MuiTableContainer-root': { boxShadow: 'none' } }}
          >
            <Table sx={{ width: '100%' }} aria-label="audit history table">
              <TableHead>
                <TableRow>
                  {auditHistoryHeader.map((label) => (
                    <TableCell key={label}>
                      <Typography variant="tableHeader">{label}</Typography>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>

              <TableBody>
                {listData.map((item, index) => (
                  <TableRowStyle key={item.createdAt}>
                    <TableCell>
                      <Typography variant="body2">
                        {listData.length - index}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="body2">{item.titleStep}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="body2">{item.reason}</Typography>
                    </TableCell>
                    <TableCell
                      onClick={(event) => handlePopoverClick(event)(index)}
                      sx={{ position: 'relative', cursor: 'pointer' }}
                    >
                      <Typography
                        variant="body2"
                        sx={{
                          maxWidth: 180,
                          overflow: 'hidden',
                          whiteSpace: 'nowrap',
                          textOverflow: 'ellipsis',
                        }}
                      >
                        {item.details}
                      </Typography>
                    </TableCell>

                    {expandedIndex === index && (
                      <>
                        {/* Popover */}
                        <Popover
                          id={id}
                          open={openPopover}
                          anchorEl={anchorEl}
                          onClose={handleClose}
                          anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                          }}
                          transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                          }}
                        >
                          <Box
                            width={1}
                            p={2}
                            border={`1px solid ${theme.palette.blue.blueBorder}99`}
                            boxShadow={`0px 4px 8px 0px ${theme.palette.common.black}33`}
                          >
                            <Typography variant="body2">
                              {item.details}
                            </Typography>
                          </Box>
                        </Popover>
                      </>
                    )}

                    <TableCell>
                      <Typography variant="body2">
                        {item.dataAccuracy}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="body2">{item.auditor}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="body2">
                        {getFormatDate(item.createdAt)}
                      </Typography>
                    </TableCell>
                  </TableRowStyle>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
    </Drawer>
  )
}

type TProps = {
  open: boolean
  toggleDrawer: (newValue: boolean, name: string) => () => void
}

export function AdditionalSubmissionHistoryDrawer({
  open,
  toggleDrawer,
}: Readonly<TProps>) {
  const theme = useTheme()

  const listData = useMemo(() => {
    return {
      auditors: [
        {
          uuid: 'fjkalsdjfkl2jq321',
          auditor: 'ธิติยา สันติสุข',
          position: 'CCM',
          considerationResults: 'ผ่าน',
          considerationResultDoc: (
            <InsertPhotoIcon sx={{ color: theme.palette.text.gray }} />
          ),
        },
        {
          uuid: '1239jfkmdasjkf',
          auditor: 'วรรณภา ศิลาดี',
          position: 'RAM',
          considerationResults: 'ผ่าน',
          considerationResultDoc: (
            <InsertPhotoIcon sx={{ color: theme.palette.text.gray }} />
          ),
        },
        {
          uuid: 'faskdlfkl123',
          auditor: 'ก้องยศ ศิริธารา',
          position: 'SRAM',
          considerationResults: 'ผ่าน',
          considerationResultDoc: (
            <InsertPhotoIcon sx={{ color: theme.palette.text.gray }} />
          ),
        },
        {
          uuid: 'fa;sdjk1231',
          auditor: 'โสรัตน์ สิริปัจทรัพย์ ',
          position: 'CADTO',
          considerationResults: 'ผ่าน',
          considerationResultDoc: (
            <InsertPhotoIcon sx={{ color: theme.palette.text.gray }} />
          ),
        },
        {
          uuid: 'fajsdkfkj123nm21m3',
          auditor: 'กีรติ จันทรสมบูรณ์ ',
          position: 'CDO',
          considerationResults: 'ผ่าน',
          considerationResultDoc: (
            <InsertPhotoIcon sx={{ color: theme.palette.text.gray }} />
          ),
        },
      ],
      docs: [
        {
          uuid: 'fajsdkfkj123nm211233',
          title: 'เอกสาร',
          considerationResultDoc: (
            <InsertPhotoIcon
              sx={{ color: theme.palette.text.gray, fontSize: '32px' }}
            />
          ),
        },
        {
          uuid: 'fajsdkfkj123nm2112fjaskdjf3',
          title: 'เอกสาร',
          considerationResultDoc: (
            <PictureAsPdfIcon // * Map image with type file
              sx={{ color: theme.palette.text.gray, fontSize: '32px' }}
            />
          ),
        },
        {
          uuid: 'fajsdkfkj123nm2112fjaskdjfjaksdjfkf3',
          title: 'เอกสาร',
          considerationResultDoc: (
            <InsertPhotoIcon
              sx={{ color: theme.palette.text.gray, fontSize: '32px' }}
            />
          ),
        },
      ],
    }
  }, [])

  return (
    <Drawer
      open={open}
      onClose={toggleDrawer(false, 'additionalSubmissionHistory')}
      anchor="right"
      sx={{
        '& .MuiPaper-root': { maxWidth: 1150, width: '100%' },
      }}
    >
      <Box>
        {/* HEADER DRAWER */}
        <HeaderStyle>
          <Typography variant="h5">ดูประวัติการส่งพิจารณาเพิ่มเติม</Typography>

          {/* Close drawer button */}
          <IconButton
            onClick={toggleDrawer(false, 'additionalSubmissionHistory')}
          >
            <CloseIcon sx={{ color: theme.palette.primary.dark }} />
          </IconButton>
        </HeaderStyle>

        {/* CONTENT TABLE */}
        <Box padding={3}>
          <Typography variant="h5" mb={2}>
            ผู้ตรวจ
          </Typography>

          <TableContainer
            component={Paper}
            sx={{ '&.MuiTableContainer-root': { boxShadow: 'none' } }}
          >
            <Table
              sx={{ width: '100%' }}
              aria-label="additional submission history table"
            >
              {/* TABLE HEAD */}
              <TableHead>
                <TableRow>
                  {additionalSubmissionHistoryHeader.map((label) => (
                    <TableCell key={label}>
                      <Typography variant="tableHeader">{label}</Typography>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>

              {/* TABLE BODY */}
              <TableBody>
                {listData.auditors.map((item) => (
                  <TableRowStyle key={item.uuid}>
                    <TableCell>
                      <Typography variant="body2">{item.auditor}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="body2">{item.position}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="body2">
                        {item.considerationResults}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="body2">
                        {item.considerationResultDoc}
                      </Typography>
                    </TableCell>
                  </TableRowStyle>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>

        <Box padding={3}>
          <Typography variant="h5" mb={2}>
            เอกสารแนบสำหรับการขอพิจารณาเพิ่มเติม
          </Typography>

          <TableContainer
            component={Paper}
            sx={{ '&.MuiTableContainer-root': { boxShadow: 'none' } }}
          >
            <Table
              sx={{ width: '100%' }}
              aria-label="attachment for additional submission table"
            >
              <TableHead>
                <TableRow>
                  <TableCell>เอกสาร</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {listData.docs.map((doc) => (
                  <TableRowStyle key={doc.uuid}>
                    <TableCell>
                      <Stack direction="row" alignItems="center" spacing={1.5}>
                        {doc.considerationResultDoc}
                        <Typography variant="body2">{doc.title}</Typography>
                      </Stack>
                    </TableCell>
                  </TableRowStyle>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
    </Drawer>
  )
}

export function EditHistoryDrawer({ open, toggleDrawer }: Readonly<TProps>) {
  const theme = useTheme()

  const listData = useMemo(() => {
    return {
      Personal: [
        {
          titleStep: 'ประวัติส่วนตัว',
          title: 'ชื่อ',
          input: 'ศิลาศักดิ์',
          reason: 'ข้อมูลส่วนตัวไม่ถูกต้อง',
          detail: 'สมัครงานคัดเลือกประเมินปฏิบัติการยุทธศาสตร์ธุรกิจ',
          memoDate: '2024-01-12 08:58:38.595593',
          updatedDate: '2024-01-20 08:58:38.595593',
        },
        {
          titleStep: 'ประวัติส่วนตัว',
          title: 'นามสกุล',
          input: 'ลาวงค์',
          reason: 'ข้อมูลส่วนตัวไม่ถูกต้อง',
          detail: 'สมัครงานคัดเลือกประเมินปฏิบัติการยุทธศาสตร์ธุรกิจ',
          memoDate: '2024-01-12 08:58:38.595593',
          updatedDate: '2024-01-20 08:58:38.595593',
        },
      ],
      'Education-Work': [
        {
          titleStep: 'ประวัติการศึกษา / ประสบการณ์การทำงาน / รายละเอียดอื่นๆ',
          title: 'การศึกษาสูงสุด',
          input: 'ปริญญาตรี/วิชาชีพเฉพาะหรือสูงกว่า',
          reason: 'ขอปฏิเสธโดยระบบ',
          detail: 'สมัครงานคัดเลือกประเมินปฏิบัติการยุทธศาสตร์ธุรกิจ',
          memoDate: '2024-01-12 08:58:38.595593',
          updatedDate: '2024-01-20 08:58:38.595593',
        },
      ],
    }
  }, [])

  return (
    <Drawer
      open={open}
      onClose={toggleDrawer(false, 'editHistory')}
      anchor="right"
      sx={{
        '& .MuiPaper-root': { maxWidth: 1150, width: '100%' },
      }}
    >
      <Box>
        {/* HEADER DRAWER */}
        <HeaderStyle>
          <Typography variant="h5">ดูประวัติการแก้ไข</Typography>

          {/* Close drawer button */}
          <IconButton onClick={toggleDrawer(false, 'editHistory')}>
            <CloseIcon sx={{ color: theme.palette.primary.main }} />
          </IconButton>
        </HeaderStyle>

        {/* CONTENT TABLE */}
        <>
          {Object.keys(listData).map((key) => (
            <Box padding={3} key={key}>
              <Typography variant="h5" mb={2}>
                {getObjectValue(TabApplicationReview, key)}
              </Typography>

              <TableContainer
                component={Paper}
                sx={{ '&.MuiTableContainer-root': { boxShadow: 'none' } }}
              >
                <Table sx={{ width: '100%' }} aria-label="edit history table">
                  <TableHead>
                    <TableRow>
                      {editHistoryHeader.map((label) => (
                        <TableCell key={label}>
                          <Typography variant="tableHeader">{label}</Typography>
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {getObjectValue(listData, key)?.map((val) => (
                      <TableRowStyle key={val.title}>
                        <TableCell>{val.title}</TableCell>
                        <TableCell>{val.input}</TableCell>
                        <TableCell>{val.reason}</TableCell>
                        <TableCell>{val.detail}</TableCell>
                        <TableCell>
                          {getFormatDate(val.memoDate, 'DD/MM/YYYY HH:mm')}
                        </TableCell>
                        <TableCell>
                          {getFormatDate(val.updatedDate, 'DD/MM/YYYY HH:mm')}
                        </TableCell>
                      </TableRowStyle>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          ))}
        </>
      </Box>
    </Drawer>
  )
}

const FooterDrawer = styled(Stack)(({ theme }) => ({
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'flex-end',
  gap: theme.spacing(3),
  borderTop: `1px solid ${theme.palette.silver.primary}`,
  padding: theme.spacing(3),
}))

export function MemoListDrawer({ open, toggleDrawer }: Readonly<TProps>) {
  const theme = useTheme()

  const memoList = useSelector(selectMemoList)
  const initProfile = useSelector(selectInitialProfile)

  const dispatch = useDispatch()

  const handleDeleteMemo = (uuid: string) => {
    // * FILTER MEMO LIST STATE FROM UUID
    dispatch(deleteMemoItem(uuid))
  }

  const dialogTitle = getTitleNotOwner(initProfile)
  const handleRequestMemoList = () => {
    dispatch(
      openCustomDialog({
        type: DIALOG_TYPE_STRING.CONFIRM,
        title: dialogTitle,
        message: `คุณต้องการที่จะส่ง ขอแก้ไขเอกสาร (${memoList.length} รายการ) ใช่หรือไม่`,
        handleConfirm: async () => {
          dispatch(setCustomDialogIsLoading(true))
          const { payload } = await dispatch(
            callRequestMemo({
              fullFormUuid: String(initProfile?.fullformUuid),
              dataMemo: memoList.map((memo: any) => ({
                uuid: memo.uuid,
                reason: memo.reason,
                reasonSection: memo.titleStep,
                reasonForMA: memo.message?.manager,
                reasonForProspect: memo.message?.prospect,
                detail: memo.details,
              })),
            })
          )

          if (payload?.status && payload?.status !== ApiStatus.ERROR) {
            dispatch(
              openCustomDialog({
                type: DIALOG_TYPE_STRING.SUCCESS,
                title: 'สำเร็จ',
                message: 'ส่งรายการขอแก้ไขเอกสารเรียบร้อยแล้ว',
                handleOK: () => {
                  window.location.reload()
                },
              })
            )
          } else {
            dispatch(closeCustomDialog())
          }
          dispatch(setCustomDialogIsLoading(false))
        },
      })
    )
  }

  return (
    <Drawer
      open={open}
      onClose={toggleDrawer(false, 'memo')}
      anchor="right"
      sx={{
        '& .MuiPaper-root': { maxWidth: 1150, width: '100%' },
      }}
    >
      <Box
        boxShadow={`0px -1px 0px 0px ${theme.palette.silver.primary}40 inset`}
      >
        {/* HEADER DRAWER */}
        <HeaderStyle>
          <Typography variant="h5">รายการขอแก้ไขเอกสาร</Typography>
        </HeaderStyle>

        <Box paddingX={3}>
          {/* Table */}
          <TableContainer
            component={Paper}
            sx={{ '&.MuiTableContainer-root': { boxShadow: 'none' } }}
          >
            <Table sx={{ width: '100%' }} aria-label="memo table">
              <TableHead>
                <TableRow>
                  {memoHeader.map((item) => (
                    <TableCell key={item.label} {...item}>
                      <Typography variant="tableHeader">
                        {item.label}
                      </Typography>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>

              <TableBody>
                {memoList.length ? (
                  memoList.map((memo, index) => (
                    <TableRowStyle key={`${memo.uuid}-${memo.details}`}>
                      <TableCell align="center">
                        <Typography variant="body2">{index + 1}</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body2">
                          {getObjectValue(TabApplicationReview, memo.titleStep)}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body2">{memo.reason}</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body2">{memo.details}</Typography>
                      </TableCell>

                      <TableCell align="center">
                        {/* DELETE ACTION */}
                        <IconButton onClick={() => handleDeleteMemo(memo.uuid)}>
                          <DeleteTwoTone color="action" />
                        </IconButton>
                      </TableCell>
                    </TableRowStyle>
                  ))
                ) : (
                  <TableRow>
                    <TableCell
                      colSpan={memoHeader.length + 1}
                      sx={{ border: 'none' }}
                      align="center"
                    >
                      <Stack justifyContent="center" alignItems="center">
                        <InboxTwoToneIcon
                          sx={{ color: `${theme.palette.common.black}8A` }}
                        />
                        <Typography variant="buttonm" color="text.lightGray">
                          ไม่พบข้อมูล
                        </Typography>
                      </Stack>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>

      <Box flexGrow={1} />

      <FooterDrawer>
        <Button
          variant="outlined"
          onClick={toggleDrawer(false, 'memo')}
          sx={{ paddingX: 7.3 }}
        >
          ยกเลิก
        </Button>
        <Button
          variant="contained"
          sx={{ textTransform: 'capitalize' }}
          disabled={!memoList.length}
          onClick={handleRequestMemoList}
        >
          ส่ง Memo ({memoList.length} รายการ)
        </Button>
      </FooterDrawer>
    </Drawer>
  )
}

export function RelatedDocsDrawer({ open, toggleDrawer }: Readonly<TProps>) {
  const theme = useTheme()

  const profileDetails = useSelector(selectProfileDetails)

  const listData =
    _.size(profileDetails?.attachments) > 0 ? profileDetails?.attachments : []

  return (
    <Drawer
      open={open}
      onClose={toggleDrawer(false, 'relatedDocs')}
      anchor="right"
    >
      {/* HEADER DRAWER */}
      <Box
        width={555}
        boxShadow={`0px -1px 0px 0px ${theme.palette.silver.primary}40 inset`}
      >
        {/* HEADER DRAWER */}
        <HeaderStyle>
          <Typography variant="h5">เอกสารที่เกี่ยวข้อง</Typography>

          {/* Close drawer button */}
          <IconButton onClick={toggleDrawer(false, 'relatedDocs')}>
            <CloseIcon sx={{ color: theme.palette.primary.dark }} />
          </IconButton>
        </HeaderStyle>

        {/* CONTENT LIST ITEM */}
        <ListStyle
          subheader={
            <Box padding={2}>
              <Typography variant="tableHeader">เอกสาร</Typography>
            </Box>
          }
          sx={{
            width: '100%',
            bgcolor: theme.palette.common.white,
            paddingX: theme.spacing(3),
          }}
        >
          {listData
            ?.filter((item) => !['signature', 'noted'].includes(item.fieldName))
            .map((item, index) => (
              <ListItem
                key={index}
                divider
                sx={{
                  '& .MuiListItemIcon-root': {
                    minWidth: 'fit-content',
                    marginRight: 1.5,
                  },
                }}
              >
                {item.type === 'image/png' || item.type === 'image/jpeg' ? (
                  <Avatar
                    variant={'square'}
                    src={getFileUrl(item.url)}
                    sx={{ mr: 1, borderRadius: 1, cursor: 'pointer' }}
                    onClick={() => {
                      dispatch(setShowFile({ isOpen: true, file: item }))
                    }}
                  />
                ) : (
                  <PictureAsPdfRoundedIcon
                    fontSize={'large'}
                    color="error"
                    sx={{ mr: 2, cursor: 'pointer' }}
                    onClick={() => {
                      dispatch(setShowFile({ isOpen: true, file: item }))
                    }}
                  />
                )}

                <ListItemText primary={getLabelFile(item.fieldName)} />
              </ListItem>
            ))}
        </ListStyle>
      </Box>
    </Drawer>
  )
}
