import {
  setHandleChange,
  setFilterTotal,
  setInitialTable,
  startLoading,
  stopLoading,
  setSearchPlaceholder,
} from '../../../../../../components/Table/redux'
import _ from 'lodash'
import { store } from '../../../../../../redux/store'
import { callAPI } from '../../../../../../utils/callAPI'
import { convertFormatDateTime } from '../../../../../../utils/convertFormatDateTime'
import { ROW_PAGE_PER_LIST } from '../../../../../../constants/table/rowPerPageList'
import { handleDownloadClick } from './onDownload'
import { handleDeleteClick } from './onDelete'
import { defaultFilter } from '../model/defaultFilter'

// ตัวแปรสำหรับเก็บ AbortController ปัจจุบัน
let currentController: AbortController | null = null

export const fetchDataList = async (mutatePropToBody: any) => {
  try {
    const { table, search, filter: filterState } = store.getState().table
    const { crudConfig } = store.getState().crud
    const { page } = table
    const { status, filterProp } = filterState
    const { searchText } = search

    const realPage = page <= 0 ? 1 : +page
    const tableProps = table
    const filter = filterProp

    if (searchText !== '') {
      if (currentController) {
        currentController.abort()
      }

      currentController = new AbortController()

      setTimeout(() => {
        store.dispatch(
          setHandleChange({ key: 'filterState', value: defaultFilter })
        )
        store.dispatch(setFilterTotal(0))
      }, 500)
    }

    // ข้อมูล body ของ API Request
    const body = {
      limit: _.get(tableProps, 'limit', ROW_PAGE_PER_LIST[0].value),
      page: realPage,
      order: tableProps.order.toString().toUpperCase(),
      sort: _.get(tableProps, 'sort', 'createdAt'),
      quickSearch: searchText,
      ...mutatePropToBody(),
      createdBy: _.get(filter, 'createdBy', ''),
      updatedBy: _.get(filter, 'updatedBy', ''),
    }

    store.dispatch(startLoading())

    // เรียกใช้ API พร้อมส่ง signal จาก AbortController
    const isCancel = await callAPI({
      method: 'post',
      url: crudConfig?.filterPath,
      body: body,
      headers: { 'x-type': crudConfig?.moduleId ?? '' },
      signal: currentController?.signal, // ส่ง signal ไปใน request
      onSuccess: (res) => {
        const row = _.get(res, 'result', []).map((item: any) => {
          return {
            ...item,
            createdAt: convertFormatDateTime({
              value: item.createdAt,
              type: 'dateTime',
            }),
            name: {
              href: `/prospect/detail/${item.uuid}`,
              text: item.name,
            },
          }
        })

        store.dispatch(
          setInitialTable({
            rows: row,
            allCount: _.get(res, 'totalCount', 0),
            headCells: crudConfig?.headerCells,
            status: status,
            handleSearch: true,
            onDownload: (selected: string[], sort: string, order: string) =>
              handleDownloadClick(selected, sort, order),
            onDelete: <T extends { uuid: string }>(selected: T) => {
              handleDeleteClick(selected.uuid, mutatePropToBody)
            },
          })
        )
      },
      onError: (e) => {
        if (e.name === 'AbortError' || e.message === 'canceled') {
          // ถ้าการยกเลิกเกิดขึ้น เราไม่ต้องการแสดงข้อผิดพลาด
          console.log('API call was aborted')
        } else {
          // ข้อผิดพลาดอื่น ๆ
          console.log('Error occurred:', e.message)

          store.dispatch(
            setInitialTable({
              rows: [],
              allCount: 0,
              headCells: crudConfig?.headerCells,
            })
          )
        }
      },
    })

    if (isCancel) store.dispatch(stopLoading())
    store.dispatch(setSearchPlaceholder('ค้นหาชื่อผู้สมัคร'))
  } catch (error: any) {
    if (error.name === 'CanceledError' || error.message === 'canceled') {
      // ถ้าข้อผิดพลาดเกิดจากการยกเลิก เราไม่ต้องการให้แสดงผลเพิ่มเติม
      console.log('Request was canceled.')
    } else {
      // จัดการข้อผิดพลาดอื่น ๆ
      console.error('An error occurred:', error.message)
    }
  }
}
