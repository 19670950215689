import _ from "lodash"
import { store } from "../../../../../../redux/store"
import { setDashboardIsLoading } from "../slices"
import { downloadFile } from "../../../../../../services/utils/downloadFile"
import { dcAdminExport } from "../../../../../../utils/apiPath"
import dayjs from "dayjs"

export const handleExportData = async () => {
    store.dispatch(setDashboardIsLoading(true))
    const { filterProp } = store.getState().table.filter

    const body = {
        startDate: _.get(filterProp, 'startDate', ''),
        endDate: _.get(filterProp, 'endDate', ''),
        downloadType: 'DASHBOARD'
    }
    const currentDate = dayjs(new Date()).format('YYYYMMDD')

    store.dispatch(
        downloadFile({
            url: dcAdminExport,
            body: body,
            fileName: `dc-admin-dashboard-${currentDate}.xlsx`,
            headers: { 'x-type': 'DC_ADMIN' },
        })
    )
    store.dispatch(setDashboardIsLoading(false))
}