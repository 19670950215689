import Modal from 'react-modal'
import {
  type Dispatch,
  memo,
  type SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react'
// mui
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import ErrorIcon from '@mui/icons-material/Error'
import MoneyOutlinedIcon from '@mui/icons-material/MoneyOutlined'
import useTheme from '@mui/material/styles/useTheme'
// components
import CustomDropdown from '../../../../components/Input/Dropdown'
import { CustomTextArea } from '../../../../components/CRUD/components/CustomTextArea'
import { crudConfig } from '../../../MasterData/reason-request-memo/crudConfig'
import { initialConfig } from '../../../../components/CRUD/handler/initialConfig'
import { fetchDataList } from '../../../../components/CRUD/handler/fetchDataList'
import { enumStatus } from '../../../../components/CRUD/enum/status'
// hooks
import useGetMessageOwnerDialog from '../../../../hooks/useGetMessageOwnerDialog'
// constants
import { ApiStatus, PAID } from '../../../../constants/status'
// redux
import { useDispatch, useSelector } from '../../../../redux/store'
import { closeDialog, openDialog } from '../../../../components/dialog/slices'
import {
  addMemoItem,
  getProfileDetails,
  getProfileDetailsDCMemo,
  setTitleStepCorrectSuccess,
  submitUpdateConsideration,
} from '../../slices/applicant-details'
import {
  selectCrudFormData,
  selectInitialProfile,
  selectProfileDetails,
  selectTableRows,
} from '../../../../redux/selector'
//
import type { MemoOptions } from '../model/memo'
import { openModal } from '../../slices/receive-payment'
import ReceivePayment from './components/ReceivePayment'
import _ from 'lodash'
import { setClearForm } from '../../../../components/CRUD/slices'
import {
  closeCustomDialog,
  openCustomDialog,
  setCustomDialogIsLoading,
} from '../../../../components/CustomDialog/slices'
import { DIALOG_TYPE_STRING } from '../../../../components/dialog/constants'
import { callFullFormUpdateConsideration } from '../services/thunk'
import { disabledConsiderationAll } from '../handler/disabledButton'
import { TabApplicationReview } from '../model/tab'
import { memoListTopic } from '../../../../constants/dc-admin/memoList'
import { getValueStr } from '../../../../utils/getValue'

type Props = {
  isLastStep?: boolean
  titleStep: string
  setTab: Dispatch<SetStateAction<number>>
}

Modal.setAppElement('#root')

const HeaderActions = memo(
  ({ isLastStep, titleStep, setTab }: Readonly<Props>) => {
    const theme = useTheme()

    const [isOpenModal, setIsOpenModal] = useState(false)

    const memoOptions = useSelector(selectTableRows) as MemoOptions[]
    const formData = useSelector(selectCrudFormData)
    const profileDetails = useSelector(selectProfileDetails)
    const initProfile = useSelector(selectInitialProfile)

    const dcMemo = profileDetails?.dcMemo ?? []

    const messageDialog = useGetMessageOwnerDialog(
      'ยืนยัน',
      'คุณต้องการยืนยันความถูกต้องของข้อมูลใช่หรือไม่'
    )

    const dispatch = useDispatch()

    const closeModal = () => {
      setIsOpenModal(false)

      // Restore scroll when modal is closed
      document.body.style.overflow = 'auto'
    }

    const submitUpdatePayment = useCallback(() => {
      // dispatch(
      //   openDialog({
      //     type: '',
      //     ...messageDialog,
      //     handleConfirm() {
      //       dispatch(updatePaymentStatus(String(initProfile?.fullformUuid)))
      //       dispatch(closeDialog())
      //     },
      //     isCloseDialog: false,
      //   })
      // )
      dispatch(openModal())
    }, [dispatch])

    const submitConsideration = async () => {
      dispatch(
        openCustomDialog({
          type: DIALOG_TYPE_STRING.CONFIRM,
          ...messageDialog,
          handleConfirm: async () => {
            dispatch(setCustomDialogIsLoading(true))
            const { payload } = await dispatch(
              callFullFormUpdateConsideration({
                fullFormUuid: String(initProfile?.fullformUuid),
                sectionName: titleStep,
              })
            )
            if (payload?.status && payload?.status !== ApiStatus.ERROR) {
              const { sectionName, status } = payload.result

              dispatch(
                openCustomDialog({
                  type: DIALOG_TYPE_STRING.SUCCESS,
                  title: 'สำเร็จ',
                  message: `ยืนยันความถูกต้องของข้อมูล${_.get(TabApplicationReview, `${sectionName}`, '')} เรียบร้อยแล้ว`,
                  handleOK: async () => {
                    dispatch(closeCustomDialog())
                    const enumSectionName = sectionName
                      .toUpperCase()
                      .replace('-', '') as typeof payload.result.sectionName

                    dispatch(
                      setTitleStepCorrectSuccess({
                        considerationName: enumSectionName,
                        status: status,
                      })
                    )

                    const newArr = dcMemo.filter(
                      (e) => e.reasonSection !== sectionName
                    )
                    dispatch(getProfileDetailsDCMemo(newArr))

                    dispatch(
                      getProfileDetails({
                        prospectId: String(initProfile?.uuid),
                      })
                    )
                    if (!isLastStep) setTab((prev) => prev + 1)
                  },
                })
              )
            } else {
              dispatch(closeCustomDialog())
            }
            dispatch(setCustomDialogIsLoading(false))
          },
        })
      )
    }

    const handleSubmitConsideration = useCallback(() => {
      dispatch(
        openDialog({
          type: '',
          ...messageDialog,
          handleConfirm() {
            dispatch(
              submitUpdateConsideration({
                fullFormUuid: String(initProfile?.fullformUuid),
                sectionName: titleStep,
              })
            )
            dispatch(closeDialog())
            // navigate to next tab
            setTab((prev) => prev + 1)
            //window.location.reload()
          },
          isCloseDialog: false,
        })
      )
    }, [dispatch, initProfile?.fullformUuid, messageDialog, setTab, titleStep])

    const handleAddMemo = useCallback(() => {
      // * ADD MEMO ITEM TO STATE (memoList)
      const {
        reasonRequestMemo: {
          uuid,
          text,
          reasonForMA,
          reasonForProspect,
          sectionID,
        },
        reasonTopic,
        details,
      } = formData

      dispatch(
        addMemoItem({
          uuid,
          reasonTopic: reasonTopic,
          reason: text,
          message: { manager: reasonForMA, prospect: reasonForProspect },
          titleStep: sectionID,
          details,
        })
      )

      closeModal()
    }, [dispatch, formData])

    const handleMemoClick = () => {
      setIsOpenModal(true)
      dispatch(setClearForm())
      // Lock scroll when modal is open
      document.body.style.overflow = 'hidden'
    }

    const handleInitialConfig = useCallback(async () => {
      // get request memo from master data
      initialConfig(crudConfig)

      await new Promise((resolve) => setTimeout(resolve, 500))

      fetchDataList(() => ({ status: [enumStatus.ACTIVE], name: '' }))
    }, [])

    // * FETCH REQUEST MEMO OPTIONS FROM MASTER DATA
    useEffect(() => {
      handleInitialConfig()
    }, [handleInitialConfig])

    return (
      <>
        <Stack
          direction="row"
          spacing={!isLastStep ? 3 : 2}
          alignSelf="flex-end"
        >
          <Button
            variant="outlined"
            startIcon={<ErrorIcon fontSize="small" />}
            color="error"
            size="medium"
            onClick={handleMemoClick}
            disabled={disabledConsiderationAll(initProfile)}
          >
            ขอแก้ไขเอกสาร
          </Button>

          {isLastStep && (
            <Button
              variant="contained"
              color="success"
              size="medium"
              startIcon={
                <MoneyOutlinedIcon
                  fontSize="small"
                  sx={{ color: theme.palette.info.contrastText }}
                />
              }
              onClick={submitUpdatePayment}
              disabled={
                profileDetails?.paymentStatus === PAID ||
                _.isNull(initProfile?.contractor) ||
                !_.isNull(profileDetails?.paymentStatus) ||
                disabledConsiderationAll(initProfile)
              }
            >
              <Typography
                variant="buttons"
                sx={{ color: theme.palette.info.contrastText }}
              >
                รับเงิน
              </Typography>
            </Button>
          )}

          {/* <Button
            variant="contained"
            size="medium"
            sx={{ color: theme.palette.common.white }}
            disabled={
              disabledConsiderationAll(initProfile) ||
              disabledBySection(memoLists, titleStep)
            }
            onClick={submitConsideration}
          >
            รับทราบ
          </Button> */}
        </Stack>
        <Modal
          isOpen={isOpenModal}
          onRequestClose={closeModal}
          contentLabel="Add memo modal"
          style={{
            overlay: {
              backgroundColor: `${theme.palette.common.black}BF`,
              zIndex: 1200, // For ensuring overlay is above other elements
            },
            content: {
              maxWidth: '678px',
              width: '100%',
              top: '50%',
              left: '50%',
              right: 'auto',
              bottom: 'auto',
              marginRight: '-50%',
              transform: 'translate(-50%, -50%)',
              padding: 0,
              backgroundColor: theme.palette.background.paper,
              borderRadius: '8px',
              boxShadow: `0px 9px 46px 8px ${theme.palette.common.black}1F,
                        0px 24px 38px 3px ${theme.palette.common.black}24,
                        0px 11px 15px -7px ${theme.palette.common.black}33`,
            },
          }}
        >
          <Box padding={2}>
            <Typography variant="h6">ขอแก้ไขเอกสาร</Typography>
          </Box>
          <Stack padding={2} pt={0} gap={2}>
            <Typography variant="body1b">ข้อมูลส่วนบุคคล</Typography>

            <CustomDropdown
              label="หัวข้อเหตุผล"
              required
              options={memoListTopic}
              isOptionEqualToValue={(option, select) =>
                option.name === select.name
              }
              getOptionLabel={(option: MemoOptions) => option.name}
              fullWidth
              placeholder="กรุณาเลือกเหตุผล"
              crud="reasonTopic"
            />

            <CustomDropdown
              label="ข้อความ"
              required
              isOptionEqualToValue={(option, select) =>
                option.text === select.text
              }
              options={memoOptions.filter(
                (item) =>
                  getValueStr(item, 'topic') ===
                  getValueStr(formData, 'reasonTopic.name')
              )}
              getOptionLabel={(option: MemoOptions) => option.text} // * text = reason to request memo
              fullWidth
              placeholder="กรุณาเลือกข้อความ"
              crud="reasonRequestMemo"
            />

            <Box>
              <Typography variant="body2" color="text.secondary">
                ข้อความ
              </Typography>

              <Box border={`1px solid ${theme.palette.text.silver}`}>
                <Stack
                  direction="row"
                  flexWrap="wrap"
                  alignItems="center"
                  bgcolor={theme.palette.background.blue}
                  borderBottom={`1px solid ${theme.palette.text.silver}`}
                >
                  <Box
                    flexBasis="50%"
                    padding={2}
                    borderRight={`1px solid ${theme.palette.text.silver}`}
                  >
                    <Typography variant="subtitle2">
                      ข้อความสำหรับ ผู้จัดการ
                    </Typography>
                  </Box>

                  <Box flexBasis="50%" padding={2}>
                    <Typography variant="subtitle2">
                      ข้อความสำหรับ ผู้สมัคร
                    </Typography>
                  </Box>
                </Stack>

                <Stack direction="row" flexWrap="wrap" alignItems="center">
                  <Box
                    flexBasis="50%"
                    padding={2}
                    borderRight={`1px solid ${theme.palette.text.silver}`}
                  >
                    <Typography variant="body2">
                      {formData?.reasonRequestMemo?.reasonForMA}
                    </Typography>
                  </Box>
                  <Box flexBasis="50%" padding={2}>
                    <Typography variant="body2">
                      {formData?.reasonRequestMemo?.reasonForProspect}
                    </Typography>
                  </Box>
                </Stack>
              </Box>
            </Box>

            <CustomTextArea
              labelName="รายละเอียดเพิ่มเติม"
              fieldName="details"
              placeholder="รายละเอียดเพิ่มเติม"
            />

            <Stack direction="row" spacing={1.5} marginLeft="auto" paddingY={1}>
              <Button variant="outlined" onClick={closeModal}>
                ยกเลิก
              </Button>
              <Button
                variant="contained"
                onClick={handleAddMemo}
                disabled={
                  _.isEmpty(formData?.reasonTopic) ||
                  _.isEmpty(formData?.reasonRequestMemo)
                }
              >
                เพิ่มลงโฟลเดอร์
              </Button>
            </Stack>
          </Stack>
        </Modal>
        <ReceivePayment fullFormUuid={String(initProfile?.fullformUuid)} />
      </>
    )
  }
)

HeaderActions.displayName = 'HeaderActions'

export default HeaderActions
