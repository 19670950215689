import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Dayjs } from 'dayjs'

export type ReceivePaymentState = {
  isShowModal: boolean
  receiveDate: Dayjs | null
  receiveTime: Dayjs | null
  receiveRemark: string
  receiveBank: string
}
const initialState: ReceivePaymentState = {
  isShowModal: false,
  receiveDate: null,
  receiveTime: null,
  receiveRemark: '',
  receiveBank: '',
}

const slice = createSlice({
  name: 'receivePayment',
  initialState,
  reducers: {
    openModal(state) {
      state.isShowModal = true
    },
    closeModal(state) {
      state.isShowModal = false
    },
    setReceiveDate(state, action: PayloadAction<Dayjs | null>) {
      state.receiveDate = action.payload
    },
    setReceiveTime(state, action: PayloadAction<Dayjs | null>) {
      state.receiveTime = action.payload
    },
    setReceiveRemark(state, action: PayloadAction<string>) {
      state.receiveRemark = action.payload
    },
    setReceiveBank(state, action: PayloadAction<string>) {
      state.receiveBank = action.payload
    },
    clearState(state) {
      state.isShowModal = initialState.isShowModal
      state.receiveDate = initialState.receiveDate
      state.receiveTime = initialState.receiveTime
      state.receiveRemark = initialState.receiveRemark
      state.receiveBank = initialState.receiveBank
    },
  },
})

// Reducer
export default slice.reducer

// Actions
export const {
  openModal,
  closeModal,
  setReceiveDate,
  setReceiveTime,
  setReceiveRemark,
  clearState,
  setReceiveBank,
} = slice.actions
