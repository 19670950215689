export enum ProcessResultApprovalStatus {
  ACTIVE = 'ACTIVE',
  DL_SUBMITTED_SHORT_FORM = 'DL_SUBMITTED_SHORT_FORM',
  DL_SUCCESSFULLY_SENT_ASSIGN_RULE = 'DL_SUCCESSFULLY_SENT_ASSIGN_RULE',
  DL_ASSIGNED_MA = 'DL_ASSIGNED_MA',
  DL_DROPPED = 'DL_DROPPED',
  DL_CHANGED = 'DL_CHANGED_MA',

  I_ACCEPTED_INTERVIEW = 'I_ACCEPTED_INTERVIEW',
  I_DECLINED_INTERVIEW = 'I_DECLINED_INTERVIEW',
  I_PASSED_INTERVIEW = 'I_PASSED_INTERVIEW',
  I_FAILED_INTERVIEW = 'I_FAILED_INTERVIEW',
  I_FAILED_OUT_OF_LIMIT = 'I_FAILED_OUT_OF_LIMIT',
  I_TIME_OUT_OF_LIMIT = 'I_TIME_OUT_OF_LIMIT',

  DS_SAVED_FULL_FORM = 'DS_SAVED_FULL_FORM',
  DS_SUBMITTED_FULL_FORM = 'DS_SUBMITTED_FULL_FORM',
  DS_REVIEWED = 'DS_REVIEWED',

  DS_REVIEWED_MEMO = 'DS_REVIEWED_MEMO',

  DCV_ACCEPTED_REVIEW = 'DCV_ACCEPTED_REVIEW',
  DCV_REQUESTED_MEMO = 'DCV_REQUESTED_MEMO',
  DS_SAVED_FULL_FORM_MEMO = 'DS_SAVED_FULL_FORM_MEMO',
  DS_SUBMITTED_FULL_FORM_MEMO = 'DS_SUBMITTED_FULL_FORM_MEMO',

  DCV_PASSED_PROSPECT_HISTORY = 'DCV_PASSED_PROSPECT_HISTORY',
  DCV_REQUESTED_DESCISION = 'DCV_REQUESTED_DESCISION',
  DCV_UPDATED_DESCISION = 'DCV_UPDATED_DESCISION',
  DCV_APPROVED_TO_BE_AGENT = 'DCV_APPROVED_TO_BE_AGENT',
  DCV_DROPPED = 'DCV_DROPPED',
  DCV_UPDATED_E_LICENCING = 'DCV_UPDATED_E_LICENCING',
  DCV_UPDATED_AGENT_CODE = 'DCV_UPDATED_AGENT_CODE',
  DCV_UPDATED_E_LICENCING_PASSED = 'DCV_UPDATED_E_LICENCING_PASSED',
  DCV_REQUESTED_DESCISION_CCM = 'DCV_REQUESTED_DESCISION_CCM',
  DCV_UPDATED_E_LICENCING_FAILED = 'DCV_UPDATED_E_LICENCING_FAILED',
  DCV_REQUESTED_RCMS = 'DCV_REQUESTED_RCMS',
  DCV_REQUESTED_E_LICENCING = 'DCV_REQUESTED_E_LICENCING',
  DCV_UPDATE_DESCISION_COMPLETED = 'DCV_UPDATE_DESCISION_COMPLETED',
  DCV_SENT_MAIL_DESCISION_CCM = 'DCV_SENT_MAIL_DESCISION_CCM',
}
export enum ApiStatus {
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
  ERROR_MAX = 'ERROR_MAX',
  ERROR_FORMAT = 'ERROR_FORMAT',
}

export enum StatusEnum {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  UPDATED = 'UPDATED',
  DELETED = 'DELETED',
}

export const STATUS_DISPLAY_TEXT = {
  DL_SUBMITTED_SHORT_FORM: 'ระบบกำลังจับคู่ผู้จัดการ',
  DL_SUCCESSFULLY_SENT_ASSIGN_RULE: 'รอมอบหมายผู้จัดการ',
  DL_ASSIGNED_MA: 'รอนัดหมาย',
  DL_DROPPED: 'ไม่ผ่านการเป็นตัวแทน',
  DL_CHANGED_MA: 'ถูกมอบหมายผู้จัดการคนใหม่',

  I_ACCEPTED_INTERVIEW: 'ระหว่างรอสัมภาษณ์',
  I_DECLINED_INTERVIEW: 'ปฏิเสธการนัดสัมภาษณ์',
  I_PASSED_INTERVIEW: 'รอกรอกฟอร์ม',
  I_FAILED_INTERVIEW: 'ไม่ผ่านการสัมภาษณ์',
  I_FAILED_OUT_OF_LIMIT: 'โดนปฎิเสธเกิน 2 ครั้ง ',

  I_TIME_OUT_OF_LIMIT: 'ไม่มีการดำเนินงานจากผู้จัดการ',

  DS_SAVED_FULL_FORM: 'เริ่มกรอกฟอร์มแบบเต็ม',
  DS_SUBMITTED_FULL_FORM: 'ส่งฟอร์มแบบเต็มสำเร็จ',
  DS_REVIEWED: 'ผู้จัดการตรวจสอบแล้ว',

  DCV_REQUESTED_MEMO: 'รอการแก้ไข',
  DS_SAVED_FULL_FORM_MEMO: 'กำลังตรวจสอบใบสมัคร/ประวัติ',
  DS_SUBMITTED_FULL_FORM_MEMO: 'กำลังตรวจสอบใบสมัคร/ประวัติ',
  DS_REVIEWED_MEMO: 'กำลังตรวจสอบใบสมัคร/ประวัติ',

  DCV_REQUESTED_RCMS: 'รอออกรหัสกับ RCMS',
  DCV_ACCEPTED_REVIEW: 'DC เริ่มตรวจสอบแล้ว',
  DCV_UPDATED_E_LICENCING_PASSED: 'รอออกรหัสกับ RCMS',
  DCV_UPDATED_E_LICENCING_FAILED: 'ไม่ผ่านการออกใบอนุญาตกับ คปภ.',
  DCV_PASSED_PROSPECT_HISTORY: 'DC ตรวจเอกสารผ่านแล้ว',
  DCV_REQUESTED_DESCISION: 'รอพิจารณา/ติดตามผล',
  DCV_UPDATED_DESCISION: 'ได้รับผลการตัดสินใจแล้ว',
  DCV_DROPPED: 'ถูกปฎิเสธ',
  DCV_UPDATED_E_LICENCING: 'DC อัปเดทผล OIC แล้ว',
  DCV_APPROVED_TO_BE_AGENT: 'ตรวจสอบสำเร็จ',
  DCV_UPDATED_AGENT_CODE: 'เป็นตัวแทนเรียบร้อย',
  DCV_REQUESTED_DESCISION_CCM: 'รอส่ง CCM',
  DCV_SENT_MAIL_DESCISION_CCM: 'รอ CCM ตรวจสอบ',
}

export type Keys =
  | 'DL_SUBMITTED_SHORT_FORM'
  | 'DL_SUCCESSFULLY_SENT_ASSIGN_RULE'
  | 'DL_ASSIGNED_MA'
  | 'DL_DROPPED'
  | 'DL_CHANGED_MA'
  | 'I_ACCEPTED_INTERVIEW'
  | 'I_DECLINED_INTERVIEW'
  | 'I_PASSED_INTERVIEW'
  | 'I_FAILED_INTERVIEW'
  | 'I_FAILED_OUT_OF_LIMIT'
  | 'I_TIME_OUT_OF_LIMIT'
  | 'DCV_UPDATED_AGENT_CODE'

export const getStatusDisplayText = (statusKey: Keys): string => {
  return STATUS_DISPLAY_TEXT[statusKey] || 'สถานะไม่ทราบ'
}

export const ERRORS_STATUS = [401, 403, 404, 500, 503]
export const ERROR_NETWORK = 'ERR_NETWORK'

export const EVER_NEVER_OPTIONS: Record<string, string> = {
  YES: 'ใช่',
  NO: 'ไม่ใช่',
}

export const HAVE_DONT_OPTIONS: Record<string, string> = {
  YES: 'มี',
  NO: 'ไม่มี',
}

export const TAX_MARITAL_STATUS: Record<string, string> = {
  M: 'โสด',
  S: 'หม้าย',
  MN: 'คู่สมรสไม่มีเงินได้',
  MC: 'คู่สมรสมีเงินได้แยกยื่นหรือรวมยื่น',
  MY: 'คู่สมรสมีเงินได้ จดทะเบียนระหว่างปี',
  MD: 'คู่สมรสมีเงินได้ หย่าหรือตายระหว่างปี',
}

export const CO_BORROWER: Record<string, string> = {
  YES: 'มีผู้กู้ร่วม',
  NO: 'ไม่มีผู้กู้ร่วม',
}

export const CONFLICT_OF_INTEREST: Record<string, string> = {
  YES: 'ใช่ ฉัน มีความขัดแย้งทางผลประโยชน์',
  NO: 'ไม่ใช่ ฉัน ไม่มีความขัดแย้งทางผลประโยชน์',
}

export const IS_CONSIDERATION_OPTIONS = {
  Completed: 'Completed',
  Pending: 'Pending',
}

export const PAID = 'PAID'
