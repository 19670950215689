export function filterMenuByPermissions(
  menu: any[],
  userPermissions: any[]
): any[] {
  return menu
    .map((item) => {
      // ตรวจสอบรายการหลัก
      const hasPermission = userPermissions.some(
        (perm) =>
          perm.permission === item.permission && perm.module === item.module
      )

      // กรองรายการย่อย (ถ้ามี)
      const filteredList = item.list
        ? item.list.filter((subItem: { permission: any }) =>
            userPermissions.some(
              (perm) =>
                perm.permission === subItem.permission &&
                perm.module === item.module
            )
          )
        : []

      // ถ้ามีสิทธิ์สำหรับรายการหลักหรือมีรายการย่อยที่ถูกกรองให้แสดงรายการนั้น
      if (hasPermission || filteredList.length > 0) {
        return { ...item, list: filteredList }
      }

      // ถ้าไม่มีสิทธิ์ใดๆ ให้ return เป็น null เพื่อกรองออกในขั้นตอนถัดไป
      return null
    })
    .filter(Boolean) // กรองค่า null ออกไป
}
