// /* eslint-disable @typescript-eslint/no-explicit-any */

import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import { useTheme } from '@mui/material'
import ChevronRightOutlined from '@mui/icons-material/ChevronRightOutlined'
import _ from 'lodash'
import { BoxCard } from '../../styled'
import { useSelector } from '../../../../../../../redux/store'
import useHistory from '../../../../../../../utils/useHistory'
import { crudConfig } from '../../../../crudConfig'
import { APPLICATION_STATUS } from '../../../../../../../constants/dc-admin'

const PedingBox = () => {
  const theme = useTheme()
  const data = useSelector((state) => state.dcAdmin.dashboard.data)
  const history = useHistory()
  return (
    <BoxCard
      sx={{ background: theme.palette.primary.main, alignItems: 'center' }}
    >
      <img
        src="/images/dashboard/person-pointing.svg"
        style={{ width: 88, position: 'absolute', left: 0, top: 35 }}
      />
      <Box
        sx={{
          width: '100%',
          pl: '67px',
          pr: 3,
          display: 'flex',
          flexDirection: 'column',
          gap: 0.5,
        }}
      >
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            gap: 2,
            alignItems: 'end',
          }}
        >
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              gap: 0.5,
            }}
          >
            <Typography variant="h5" color="text.white">
              รอดำเนินการ
            </Typography>
            <Typography color="text.white">
              จำนวนงานที่กำลังรอดำเนินการ
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', gap: 1.5, alignItems: 'end' }}>
            <Typography
              color="text.white"
              sx={{ fontSize: 56, lineHeight: '40px', fontWeight: 700 }}
            >
              {_.get(data, 'pending', 0)}
            </Typography>
            <Typography color="text.white">งาน</Typography>
          </Box>
        </Box>
        <Box sx={{ display: 'flex', gap: 1.5, alignItems: 'end' }}>
          <Typography color="text.white" sx={{ width: '100%' }}>
            จากจำนวนงานที่มีทั้งหมด
          </Typography>
          <Typography
            color="text.white"
            sx={{ width: 45, textAlign: 'center' }}
          >
            {_.get(data, 'total', 0)}
          </Typography>
          <Typography color="text.white">งาน</Typography>
        </Box>
      </Box>
      <IconButton
        sx={{ position: 'absolute', bottom: 24, right: 24, p: 0 }}
        onClick={() =>
          history.push(
            `${crudConfig.modulePath}?status=${APPLICATION_STATUS.PENDING}`
          )
        }
      >
        <ChevronRightOutlined htmlColor="#FFF" />
      </IconButton>
    </BoxCard>
  )
}

export default PedingBox
