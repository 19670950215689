import { Box, Grid, Typography } from '@mui/material'
import { shallowEqual, useSelector } from 'react-redux'
import { CustomCard } from '../../../../../components/Layout/CustomCard'
import { DateFormat } from '../../../../../constants/dateFormat'
import { SliceModel } from '../../../../../redux/models'
import { convertFormatDateTimeV2 } from '../../../../../utils/convertFormatDateTime'
import { formatEmail } from '../../utils/fotmatTextDisplay'
import {
  getLabelShortFormArrText,
  ShortFormText,
} from '../../../../../constants/shortForm'

export default function CardDetail() {
  const { data, isLoading } = useSelector(
    (state: SliceModel) => ({
      data: state.prospect.detail.data,
      isLoading: state.prospect.detail.isLoading,
    }),
    shallowEqual
  )
  return (
    <>
      <Box
        display={'flex'}
        mb={2}
        justifyContent="space-between"
        alignItems={'center'}
      >
        <Box pt={1}>
          <Typography variant="h5" color={'primary'}>
            ข้อมูลผู้สมัคร
          </Typography>
        </Box>
      </Box>

      <CustomCard
        isLoading={isLoading}
        sx={{ mb: { xs: 2, sm: 3 }, minHeight: '300px' }}
      >
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Typography sx={{ color: 'text.gray' }}>Temp Code</Typography>
            <Typography variant="body1b">{data?.tempCode}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography sx={{ color: 'text.gray' }}>วันที่ลงทะเบียน</Typography>
            <Typography variant="body1b">
              {convertFormatDateTimeV2(data?.registeredAt, DateFormat.DDMMYYYY)}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography sx={{ color: 'text.gray' }}>
              วัน/เดือน/ปีเกิด
            </Typography>
            <Typography variant="body1b">
              {convertFormatDateTimeV2(data?.dateOfBirth)}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography sx={{ color: 'text.gray' }}>
              จังหวัดที่สะดวกปฎิบัติงาน
            </Typography>
            <Typography variant="body1b">{data?.workingArea}</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography sx={{ color: 'text.gray' }}>อีเมล</Typography>
            <Typography variant="body1b">
              {formatEmail(data?.email, 50)}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography sx={{ color: 'text.gray' }}>
              เบอร์โทรศัพท์มือถือ
            </Typography>
            <Typography variant="body1b">{data?.mobileNo}</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography sx={{ color: 'text.gray' }}>
              เวลาสะดวกให้ติดต่อกลับ
            </Typography>
            <Typography variant="body1b">{data?.spareTime}</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography sx={{ color: 'text.gray' }}>
              เห็นโฆษณาจากช่องทางใด
            </Typography>
            <Typography variant="body1b">
              {data?.knowUsFromChannelTH}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography sx={{ color: 'text.gray' }}>
              สนใจทำธุรกิจผ่านช่องทางใด
            </Typography>
            <Typography variant="body1b">
              {getLabelShortFormArrText(
                ShortFormText.business_social_media_channel_th,
                data?.workingStyle
              )}
            </Typography>
          </Grid>
        </Grid>
      </CustomCard>
    </>
  )
}
