export const headerCells: any = [
  {
    id: 'name',
    label: 'ชื่อ DC Admin',
  },
  {
    id: 'total',
    label: 'จำนวนงานทั้งหมด',
  },
  {
    id: 'inProgress',
    label: 'กำลังดำเนินการ',
  },
  {
    id: 'requestEdit',
    label: 'ขอแก้ไขเอกสาร',
  },
  {
    id: 'waitingForLicense',
    label: 'คปภ.',
  },
  {
    id: 'rcms',
    label: 'RCMS',
  },
  {
    id: 'waitingForApprove',
    label: 'ส่งพิจารณาเพิ่มเติม',
  },
  {
    id: 'success',
    label: 'สำเร็จ',
  },
]
