import { Box, Stack, Button } from '@mui/material'
import _ from 'lodash'
import { CustomInput } from '../../../components/CRUD/components/CustomInput'
import { CustomTextArea } from '../../../components/CRUD/components/CustomTextArea'
import { UploadMultiReturnPath } from '../../../components/FullUpload'
import { CustomLoadingButton } from '../../../components/Input/CustomButton'
import { theme } from '../../../styles/themes/theme'
import { useSelector } from 'react-redux'
import {
  selectCrudFormData,
  selectCrudLoading,
  selectInitialProfile,
} from '../../../redux/selector'

import {
  closeCustomDialog,
  openCustomDialog,
} from '../../../components/CustomDialog/slices'

import { ApiStatus } from '../../../constants/status'
import { dispatch } from '../../../redux/store'
import { getNotes } from '../slices/applicant-details'
import { callCreateNote } from './services/thunk'
import { getValue } from '../../../utils/getValue'
import { DIALOG_TYPE_STRING } from '../../../components/dialog/constants'

export const NoteForm = () => {
  const initProfile = useSelector(selectInitialProfile)
  const fullFormUuid = String(initProfile?.fullformUuid)
  const formData = useSelector(selectCrudFormData)
  const isLoading = useSelector(selectCrudLoading)
  const handleSubmitAddRemark = async () => {
    const { note } = formData

    const fullFormUuid = String(initProfile?.fullformUuid)

    const { payload } = await dispatch(
      callCreateNote({
        fullFormUuid: fullFormUuid,
        data: {
          fullFormUuid: fullFormUuid,
          toppic: note.title,
          detail: note.details,
          contentId: getValue(note.files, 'id'),
        },
      })
    )

    if (payload?.status && payload?.status !== ApiStatus.ERROR) {
      dispatch(
        openCustomDialog({
          type: DIALOG_TYPE_STRING.SUCCESS,
          title: 'สำเร็จ',
          message: 'บันทึกหมายเหตุเรียบร้อยแล้ว',
          handleOK: async () => {
            await dispatch(getNotes(fullFormUuid))

            dispatch(closeCustomDialog())
          },
        })
      )
    }
  }
  return (
    <>
      <Stack
        gap={2}
        pt={0}
        sx={{
          '& .MuiButtonBase-root.basic-upload': {
            color: theme.palette.primary.main,
            backgroundColor: theme.palette.primary.contrastText,
            boxShadow: 'none',
          },
        }}
      >
        <CustomInput
          labelName="หัวข้อ"
          placeholder="หัวข้อ"
          fieldName="note.title"
          required
        />
        <CustomTextArea
          fieldName="note.details"
          placeholder="รายละเอียดเพิ่มเติม"
          labelName="รายละเอียดเพิ่มเติม"
          required
        />

        <Box>
          <UploadMultiReturnPath
            fullFormUuid={fullFormUuid}
            fieldName="noted"
            name={'note.files'}
            label={'แนบเอกสาร'}
            maxFile={1}
          />
        </Box>

        <Stack
          direction="row"
          alignItems="center"
          spacing={1.5}
          marginLeft="auto"
          pt={1}
        >
          <Button
            variant="outlined"
            onClick={() => dispatch(closeCustomDialog())}
          >
            ยกเลิก
          </Button>
          <CustomLoadingButton
            loading={isLoading}
            variant="contained"
            disabled={
              _.isEmpty(formData?.note?.title) ||
              _.isEmpty(formData?.note?.details)
            }
            onClick={handleSubmitAddRemark}
          >
            บันทึก
          </CustomLoadingButton>
        </Stack>
      </Stack>
    </>
  )
}
