import { APPLICATION_STATUS } from '../../../../../../constants/dc-admin'

export const dashboardItem = (theme: any) => ({
  requestEdit: {
    title: 'ขอแก้ไขเอกสาร',
    subTitle: 'จำนวนผู้สมัครที่ติด Memo',
    unit: 'คน',
    icon: '/icons/icon-warning-document.svg',
    status: APPLICATION_STATUS.MEMO,
    backgroundColor: theme.palette?.error?.main,
  },
  success: {
    title: 'สำเร็จ',
    subTitle: 'จำนวนงานที่เสร็จสิ้น',
    unit: 'งาน',
    icon: '/icons/icon-receive-success.svg',
    status: APPLICATION_STATUS.SUCCESS,
    backgroundColor: theme.palette?.success?.main,
  },
  inProgress: {
    title: 'กำลังตรวจสอบ',
    subTitle: 'จำนวนงานที่กำลังทำ',
    unit: 'งาน',
    icon: '/icons/icon-report.svg',
    status: APPLICATION_STATUS.PROCESS,
  },
  waitingForLicense: {
    title: 'คปภ.',
    subTitle: 'จำนวนผู้สมัครที่กำลังออกใบอนุญาต',
    unit: 'คน',
    icon: '/icons/icon-certificate.svg',
    status: APPLICATION_STATUS.OIC,
  },
  rcms: {
    title: 'RCMS',
    subTitle: 'จำนวนผู้สมัครที่กำลังออกรหัส',
    unit: 'คน',
    icon: '/icons/icon-license.svg',
    status: APPLICATION_STATUS.RCMS,
  },
  waitingForApprove: {
    title: 'ส่งพิจารณาเพิ่มเติม',
    subTitle: 'จำนวนผู้สมัครที่กำลังรอการอนุมัติ',
    unit: 'คน',
    icon: '/icons/icon-mail-pending.svg',
    status: APPLICATION_STATUS.REQUEST_DESCISION,
    backgroundColor: theme.palette?.warning?.light,
  },
})
