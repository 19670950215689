import Modal from 'react-modal'
import { useState, useEffect } from 'react'
// mui
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableContainer, {
  TableContainerProps,
} from '@mui/material/TableContainer'
import Paper from '@mui/material/Paper'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import Popover from '@mui/material/Popover'
import IconButton from '@mui/material/IconButton'
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone'
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf'

import AddIcon from '@mui/icons-material/Add'
import InboxTwoTone from '@mui/icons-material/InboxTwoTone'
import styled from '@mui/material/styles/styled'
import useTheme from '@mui/material/styles/useTheme'

// redux
import { useDispatch, useSelector } from '../../../redux/store'
import { clearUploadedData } from '../../../components/Upload/slices'
import {
  submitCreateNote,
  getNotes,
  setShowFile,
} from '../slices/applicant-details'
import {
  selectCrudFormData,
  selectInitialProfile,
  selectRemarks,
} from '../../../redux/selector'
//
import { notesTableHeader } from './model/notes-table'
import { setFormInit } from '../../../components/CRUD/slices'
import Avatar from '@mui/material/Avatar'
import { getFileUrl } from '../../../utils/getFileUrl'
import _ from 'lodash'
import { getValue } from '../../../utils/getValue'
import { callDeleteNote } from './services/thunk'
import { ApiStatus } from '../../../constants/status'
import {
  closeCustomDialog,
  openCustomDialog,
  setCustomDialogIsLoading,
} from '../../../components/CustomDialog/slices'
import { DIALOG_TYPE_STRING } from '../../../components/dialog/constants'
import { NoteForm } from './NoteForm'

Modal.setAppElement('#root')

const TableContainerStyle = styled(TableContainer)<TableContainerProps>(
  ({ theme }) => ({
    border: `1px solid ${theme.palette.blue.blueBorder}`,
    boxShadow: `0px 12px 40px 0px ${theme.palette.blue.oceanBlue}1A`,
    padding: theme.spacing(3),
  })
)

const TableRowStyle = styled(TableRow)(({ theme }) => ({
  '& .MuiTableCell-root ': {
    borderColor: theme.palette.blue.blueBorder,
  },

  '&.MuiTableRow-root:nth-of-type(odd)': {
    backgroundColor: theme.palette.background.blue,
  },
}))

export default function Notes() {
  const theme = useTheme()

  const dispatch = useDispatch()

  const [isOpenModal, setIsOpenModal] = useState(false)
  const isLoading = useSelector((state) => state.applicantDetails.isLoading)
  const formData = useSelector(selectCrudFormData)
  const remarks = useSelector(selectRemarks)
  const initProfile = useSelector(selectInitialProfile)

  const closeModal = () => {
    setIsOpenModal(false)

    dispatch(setFormInit(null))
    document.body.style.overflow = 'auto'
  }

  const openModal = () => {
    setIsOpenModal(true)

    document.body.style.overflow = 'hidden'
  }

  const [anchorEl, setAnchorEl] = useState<HTMLTableCellElement | null>(null)
  const [expandedIndex, setExpandedIndex] = useState<null | number>(null)

  const handlePopoverClick =
    (event: React.MouseEvent<HTMLTableCellElement>) => (index: number) => {
      setAnchorEl(event.currentTarget)
      setExpandedIndex(index)
    }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleAddRemark = async () => {
    const { note } = formData

    await dispatch(
      submitCreateNote({
        fullFormUuid: String(initProfile?.fullformUuid),
        data: {
          fullFormUuid: String(initProfile?.fullformUuid),
          toppic: note.title,
          detail: note.details,
          contentId: getValue(note.files, 'id'),
        },
      })
    )
    dispatch(getNotes(String(initProfile?.fullformUuid)))

    closeModal()
  }

  const handleDeleteRemark = async (contentId: number) => {
    const fullFormUuid = String(initProfile?.fullformUuid)

    dispatch(
      openCustomDialog({
        type: DIALOG_TYPE_STRING.CONFIRM,
        title: 'ยืนยัน',
        message: 'ยืนยันการลบข้อมูลใช่หรือไม่',
        handleConfirm: async () => {
          dispatch(setCustomDialogIsLoading(true))
          const { payload } = await dispatch(
            callDeleteNote({
              fullFormUuid: fullFormUuid,
              contentId: contentId,
            })
          )
          dispatch(setCustomDialogIsLoading(false))
          if (payload?.status && payload?.status !== ApiStatus.ERROR) {
            dispatch(
              openCustomDialog({
                type: DIALOG_TYPE_STRING.SUCCESS,
                title: 'สำเร็จ',
                message: 'ลบหมายเหตุสำเร็จแล้ว',
                handleOK: async () => {
                  await dispatch(getNotes(fullFormUuid))
                  dispatch(closeCustomDialog())
                },
              })
            )
          } else {
            dispatch(closeCustomDialog())
          }
        },
      })
    )
    closeModal()
  }
  const openPopover = Boolean(anchorEl)
  const id = openPopover ? 'audit-history-details' : undefined

  useEffect(() => {
    if (initProfile?.fullformUuid) {
      dispatch(getNotes(initProfile.fullformUuid))
    }
  }, [dispatch, initProfile?.fullformUuid])

  // INITIAL FOR UPLOAD FILE
  useEffect(() => {
    localStorage.removeItem('uploadIcon')
    dispatch(clearUploadedData())
    dispatch(setFormInit(null))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <TableContainerStyle component={Paper}>
        <Stack alignItems="flex-end" mb={3}>
          <Button
            variant="contained"
            size="medium"
            startIcon={
              <AddIcon
                fontSize="small"
                sx={{ color: theme.palette.info.contrastText }}
              />
            }
            onClick={() =>
              dispatch(
                openCustomDialog({
                  type: DIALOG_TYPE_STRING.CUSTOM,
                  title: 'หมายเหตุ',
                  content: <NoteForm />,
                })
              )
            }
            disabled={_.isNull(initProfile?.contractor)}
          >
            สร้าง หมายเหตุ
          </Button>
        </Stack>

        <Table sx={{ minWidth: 650 }} aria-label="notes table">
          <TableHead>
            <TableRow>
              {notesTableHeader.map((note) => (
                <TableCell
                  component="th"
                  key={note.label}
                  sx={{ border: 'none' }}
                  {...note}
                >
                  {note.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody>
            {remarks.length > 0 ? (
              remarks.map((remark: any, index: number) => (
                <TableRowStyle key={remark.title}>
                  <TableCell sx={{ width: 320 }}>{remark.title}</TableCell>
                  <TableCell
                    onClick={(event) => handlePopoverClick(event)(index)}
                    sx={{ position: 'relative', cursor: 'pointer' }}
                  >
                    <Typography
                      variant="body2"
                      sx={{
                        maxWidth: 540,
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                      }}
                    >
                      {remark.details}
                    </Typography>
                  </TableCell>

                  {expandedIndex === index && (
                    <>
                      {/* Popover */}
                      <Popover
                        id={id}
                        open={openPopover}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                          vertical: 'top',
                          horizontal: 'left',
                        }}
                        transformOrigin={{
                          vertical: 'top',
                          horizontal: 'left',
                        }}
                        sx={{ '& .MuiPopover-paper': { maxWidth: 540 } }}
                      >
                        <Box
                          width={1}
                          p={2}
                          border={`1px solid ${theme.palette.blue.blueBorder}99`}
                          boxShadow={`0px 4px 8px 0px ${theme.palette.common.black}33`}
                        >
                          <Typography variant="body2">
                            {remark.details}
                          </Typography>
                        </Box>
                      </Popover>
                    </>
                  )}

                  <TableCell align="center">
                    {_.size(remark.attachDoc) > 0 ? (
                      <>
                        {getValue(remark.attachDoc, 'type', '').includes(
                          'image'
                        ) ? (
                          <Avatar
                            variant={'square'}
                            src={getFileUrl(
                              getValue(remark.attachDoc, 'url', '')
                            )}
                            sx={{
                              borderRadius: 1,
                              margin: 'auto',
                              display: 'block',
                              cursor: 'pointer',
                            }}
                            onClick={() => {
                              dispatch(
                                setShowFile({
                                  isOpen: true,
                                  file: _.get(remark, `attachDoc[0]`, null),
                                })
                              )
                            }}
                          />
                        ) : (
                          <PictureAsPdfIcon
                            fontSize={'large'}
                            color="error"
                            sx={{
                              margin: 'auto',
                              display: 'block',
                              cursor: 'pointer',
                            }}
                            onClick={() => {
                              dispatch(
                                setShowFile({
                                  isOpen: true,
                                  file: _.get(remark, `attachDoc[0]`, null),
                                })
                              )
                            }}
                          />
                        )}
                      </>
                    ) : (
                      <></>
                    )}
                  </TableCell>
                  <TableCell align="center">
                    <Stack
                      direction="row"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <IconButton
                        onClick={async () =>
                          handleDeleteRemark(Number(remark?.contentId))
                        }
                      >
                        <DeleteTwoToneIcon fontSize="small" />
                      </IconButton>
                    </Stack>
                  </TableCell>
                </TableRowStyle>
              ))
            ) : (
              <TableRow>
                <TableCell
                  colSpan={notesTableHeader.length + 1}
                  sx={{ border: 'none' }}
                  align="center"
                >
                  <Stack justifyContent="center" alignItems="center">
                    <InboxTwoTone
                      sx={{ color: `${theme.palette.common.black}8A` }}
                    />
                    <Typography variant="buttonm" color="text.lightGray">
                      ไม่พบข้อมูล
                    </Typography>
                  </Stack>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainerStyle>
    </>
  )
}
