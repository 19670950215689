import Box from '@mui/material/Box'
import styled from '@mui/system/styled'

export const StyledSubContainer = styled(Box)(({ theme }) => ({
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    gap: theme.spacing(2),
    marginLeft: theme.spacing(3),
}))

export const StyledSubBox = styled(Box)(({ theme }) => ({
    width: 'calc(50% - 8px)',
    height: 66,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(1.5),
    borderRadius: theme.spacing(2),
    background: theme.palette?.background?.blue,
}))