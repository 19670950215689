import { useState } from 'react'
import Modal from 'react-modal'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
// redux
import { useSelector } from '../../../../redux/store'
import {
  selectInitialProfile,
  selectProgressStatus,
  selectDataLicense,
  selectProfileDetails,
} from '../../../../redux/selector'
import { applicationStatusStep } from '../../slices/applicant-details'
// components
import Image from '../../../../components/Image/Image'
// utils
import { getFormatDate } from '../../../../utils/getFormatDate'
//
import { CardContentStyle, CardStyle } from '../styled'
import AdditionalInfoDialog from './AdditionalInfoDialog'
import DownloadRoundedIcon from '@mui/icons-material/DownloadRounded'
import { formatAgentCode } from '../../../../utils/formatAgentCode'

import { LoadingButton } from '@mui/lab'
import { downloadPdf } from '../../../../services/utils/downloadFile'
import { getStorageUrl } from '../../../../components/FullUpload/handler/getStorageUrl'
import { getValueStr } from '../../../../utils/getValue'
Modal.setAppElement('#root')

export default function CodeIssuedStatus() {
  const initProfile = useSelector(selectInitialProfile)
  const profileDetails = useSelector(selectProfileDetails)

  const applicationStatus = useSelector(selectProgressStatus)
  const dataLicense = useSelector(selectDataLicense)
  const [isOpenModal, setIsOpenModal] = useState(false)
  const [downloading, setDownloading] = useState(false)

  const openModal = () => {
    setIsOpenModal(true)

    document.body.style.overflow = 'hidden'
  }

  const closeModal = () => {
    setIsOpenModal(false)

    document.body.style.overflow = 'auto'
  }
  const downloadContract = async () => {
    setDownloading(true)

    const fileUrl = getStorageUrl(
      getValueStr(profileDetails, 'fullFormFinalFile')
    )
    const fileName = `${getValueStr(profileDetails, 'personal.idCard')}_contract.pdf`
    await downloadPdf(fileUrl, fileName)
    setDownloading(false)
  }

  return (
    <>
      <CardStyle>
        <CardContentStyle>
          <Stack direction="row" gap={2} alignItems={'center'}>
            <Image
              src="/images/illustrate_code_issued.svg"
              alt="Code issued process"
              effect="opacity"
              sizes="56"
              sx={{
                '& .MuiBox-root': {
                  verticalAlign: 'middle',
                  objectFit: 'contain',
                },
              }}
            />
            <Stack flex={1} justifyContent="center" gap={0.5}>
              <Typography variant="h5" color="success.main">
                {applicationStatus && applicationStatusStep[applicationStatus]}
              </Typography>
              <Typography variant="body2" color="text.gray">
                วันที่: {getFormatDate(String(initProfile?.statsuUpdateDate))}
              </Typography>
            </Stack>
            <Stack>
              <LoadingButton
                variant={'outlined'}
                size="small"
                endIcon={<DownloadRoundedIcon fontSize="small" />}
                loading={downloading}
                onClick={downloadContract}
              >
                ดาวน์โหลดเอกสาร
              </LoadingButton>
            </Stack>
          </Stack>
        </CardContentStyle>
      </CardStyle>

      <CardStyle>
        <CardContentStyle>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            gap={2}
          >
            <Box>
              <Typography variant="body2" color="text.gray">
                เลขที่ใบอนุญาต
              </Typography>
              <Typography variant="h6">
                {dataLicense?.licenseNumber ?? '-'}
              </Typography>
            </Box>
            <Box>
              <Typography variant="body2" color="text.gray">
                รหัสตัวแทน
              </Typography>
              <Typography variant="h6">
                {dataLicense?.agentCode
                  ? formatAgentCode(dataLicense?.agentCode)
                  : '-'}
              </Typography>
            </Box>
            <Button
              variant="outlined"
              size="small"
              endIcon={<ArrowForwardIcon fontSize="small" />}
              onClick={openModal}
            >
              ดูเพิ่มเติม
            </Button>
          </Stack>
        </CardContentStyle>
      </CardStyle>

      {/* ADDITIONAL INFO DIALOG */}
      <AdditionalInfoDialog isOpenModal={isOpenModal} closeModal={closeModal} />
    </>
  )
}
