import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
// redux
import { useSelector } from '../../../../redux/store'
import {
  selectInitialProfile,
  selectProgressStatus,
} from '../../../../redux/selector'
import { applicationStatusStep } from '../../slices/applicant-details'
// components
import Image from '../../../../components/Image/Image'
// utils
import { getFormatDate } from '../../../../utils/getFormatDate'
//
import { CardContentStyle, CardStyle } from '../styled'

export default function CheckingApplicationStatus() {
  const initProfile = useSelector(selectInitialProfile)
  const applicationStatus = useSelector(selectProgressStatus)

  return (
    <CardStyle>
      <CardContentStyle>
        <Stack direction="row" gap={2}>
          <Image
            src="/images/illustrate_checking_application.svg"
            alt="Checking application process"
            effect="opacity"
            sizes="56"
            sx={{
              '& .MuiBox-root': {
                verticalAlign: 'middle',
                objectFit: 'contain',
              },
            }}
          />
          <Stack flex={1} gap={2}>
            <Typography variant="h5" color="primary.main">
              {applicationStatus && applicationStatusStep[applicationStatus]}
            </Typography>
            <Typography variant="body2" color="text.gray">
              วันที่: {getFormatDate(String(initProfile?.statsuUpdateDate))}
            </Typography>
          </Stack>
        </Stack>
      </CardContentStyle>
    </CardStyle>
  )
}
