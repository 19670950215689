export interface DecisionBody {
  fullFormUuid: string
  hasSendCCM: boolean
  hasSendRam: boolean
  RamUuid: string
  hasSendSRam: boolean
  SRamUuid: string
  hasSendCADTO: boolean
  CADTOUuid: string
  hasSendCDO: boolean
  CDOUuid: string
  detail: string
  metaId: number[]
}

export enum ActionByEnum {
  SRAM = 'SRAM',
  RAM = 'RAM',
  CADTO = 'CADTO',
  CDO = 'CDO',
}
export interface ResultConsiderationBody {
  fullFormUuid: string
  actionBy: ActionByEnum
  action: string // Request , Confirm
  metaId?: number[]
  result?: boolean // ถ้าเป็น Request คือ ยืนยันผล ต้องมี result
}
export interface CcmResultBody {
  fullFormUuid: string
  metaId: number[]
}
export enum DecisionStatus {
  COMPLETED = 'COMPLETED',
  PENDING = 'PENDING',
  REJECTED = 'REJECTED',
}
export interface HistoryAuditBody {
  fullFormUuid: string
  limit?: number
  page?: number
  order?: string
  sort?: string
}
