import Modal from 'react-modal'
import { type MouseEvent, useCallback, useState } from 'react'
import useTheme from '@mui/material/styles/useTheme'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
// components
import Image from '../../../../components/Image/Image'
// redux
import { dispatch, useDispatch, useSelector } from '../../../../redux/store'
import {
  applicationStatusStep,
  submitLicenseAgent,
} from '../../slices/applicant-details'
import {
  selectCrudFormData,
  selectProgressStatus,
  selectInitialProfile,
  selectDataLicense,
} from '../../../../redux/selector'
//
import { CardContentStyle, CardStyle } from '../styled'
import { getFormatDate } from '../../../../utils/getFormatDate'
import { LoadingButton } from '@mui/lab'
import AdditionalInfoDialog from './AdditionalInfoDialog'
import {
  closeCustomDialog,
  openCustomDialog,
} from '../../../../components/CustomDialog/slices'
import { DIALOG_TYPE_STRING } from '../../../../components/dialog/constants'
import { CustomLoadingButton } from '../../../../components/Input/CustomButton'
import { ApiStatus } from '../../../../constants/status'
import { callLicenseAgent } from '../services/thunk'
import CustomTextfield from '../../../../components/Input/CustomTextfield'
import _ from 'lodash'
import { getTitleNotOwner } from '../handler/getTitleNotOwner'

const initialModal = {
  submitAgentCode: false,
  seeMore: false,
}

export default function AwaitingAgentCodeStatus() {
  const initProfile = useSelector(selectInitialProfile)
  const applicationStatus = useSelector(selectProgressStatus)
  const formData = useSelector(selectCrudFormData)
  const [isOpenModal, setIsOpenModal] =
    useState<Record<string, boolean>>(initialModal)
  const { isLoading } = useSelector((state) => state.applicantDetails)
  const dataLicense = useSelector(selectDataLicense)
  const dispatch = useDispatch()

  const theme = useTheme()
  const title = getTitleNotOwner(initProfile, 'กรอกรหัส')
  const openModal = (event: MouseEvent<HTMLButtonElement>) => {
    setIsOpenModal((prev) => ({
      ...prev,
      [event.currentTarget.name]: true,
    }))

    document.body.style.overflow = 'hidden'
  }

  const closeModal = () => {
    setIsOpenModal(initialModal)

    document.body.style.overflow = 'auto'
  }

  const submitAgentCode = useCallback(() => {
    dispatch(
      submitLicenseAgent({
        fullFormUuid: String(initProfile?.fullformUuid),
        agentCode: formData?.agentCode,
      })
    )

    closeModal()
  }, [dispatch, formData?.agentCode, initProfile?.fullformUuid])

  return (
    <>
      <CardStyle>
        <CardContentStyle>
          <Stack direction="row" alignItems="center" gap={2}>
            <Image
              src="/images/illustrate_awaiting_agent_code.svg"
              alt="Awaiting RMCS code process"
              effect="opacity"
              sizes="56"
              sx={{
                '& .MuiBox-root': {
                  verticalAlign: 'middle',
                  objectFit: 'contain',
                },
              }}
            />

            <Stack flex={1} justifyContent="center" gap={0.5}>
              <Typography variant="h5" color="primary.main">
                {applicationStatus && applicationStatusStep[applicationStatus]}
              </Typography>
              <Typography variant="body2" color="text.gray">
                วันที่: {getFormatDate(String(initProfile?.statsuUpdateDate))}
              </Typography>
            </Stack>

            <Button
              variant="outlined"
              size="small"
              fullWidth
              endIcon={<ArrowForwardIcon fontSize="small" />}
              sx={{ maxWidth: 'fit-content' }}
              onClick={() =>
                dispatch(
                  openCustomDialog({
                    type: DIALOG_TYPE_STRING.CUSTOM,
                    title: `${title}`,
                    content: <AwaitingAgentCodeStatusForm />,
                  })
                )
              }
              name="submitAgentCode"
            >
              กรอกรหัส
            </Button>
          </Stack>
        </CardContentStyle>
      </CardStyle>
      <CardStyle>
        <CardContentStyle>
          <Stack direction="row" alignItems="center" gap={2}>
            <Box flex={1}>
              <Typography variant="body2" color="text.gray">
                เลขที่ใบอนุญาต
              </Typography>
              <Typography variant="h6">
                {dataLicense?.licenseNumber ?? '-'}
              </Typography>
            </Box>

            <Box flex={1}>
              <Typography variant="body2" color="text.gray">
                รหัสตัวแทน
              </Typography>
              <Typography variant="h6">-</Typography>
            </Box>

            <Button
              variant="outlined"
              size="small"
              endIcon={<ArrowForwardIcon fontSize="small" />}
              onClick={openModal}
              name="seeMore"
            >
              ดูเพิ่มเติม
            </Button>
          </Stack>
        </CardContentStyle>
      </CardStyle>

      {/* ENTER AGENT CODE DIALOG */}
      <Modal
        isOpen={isOpenModal.submitAgentCode}
        onRequestClose={closeModal}
        contentLabel="Awaiting RMCS code modal"
        style={{
          overlay: {
            backgroundColor: `${theme.palette.common.black}BF`,
            zIndex: 1200, // For ensuring overlay is above other elements
          },
          content: {
            maxWidth: '678px',
            width: '100%',
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            padding: '1rem',
            backgroundColor: theme.palette.background.paper,
            borderRadius: '8px',
            boxShadow: `0px 9px 46px 8px ${theme.palette.common.black}1F,
                        0px 24px 38px 3px ${theme.palette.common.black}24,
                        0px 11px 15px -7px ${theme.palette.common.black}33`,
          },
        }}
      >
        <Stack gap={2}>
          <Typography variant="h6">กรอกรหัสตัวแทน</Typography>

          <CustomTextfield
            required
            label="รหัสตัวแทน"
            crud="agentCode"
            placeholder="เลขที่ใบอนุญาต"
            maxLength={14}
            valueType="number"
          />

          <Stack direction="row" spacing={1.5} marginLeft="auto" paddingY={1}>
            <Button variant="outlined" onClick={closeModal}>
              ยกเลิก
            </Button>
            <LoadingButton
              variant="contained"
              onClick={submitAgentCode}
              disabled={formData?.agentCode?.length !== 14}
              loading={isLoading}
              loadingPosition="end"
              endIcon={<></>}
            >
              บันทึก
            </LoadingButton>
          </Stack>
        </Stack>
      </Modal>

      {/* ADDITIONAL INFORMATION DIALOG */}
      <AdditionalInfoDialog
        isOpenModal={isOpenModal.seeMore}
        closeModal={closeModal}
      />
    </>
  )
}
export const AwaitingAgentCodeStatusForm = () => {
  const [isLoading, setIsLoading] = useState(false)
  const formData = useSelector(selectCrudFormData)
  const initProfile = useSelector(selectInitialProfile)
  const fullFormUuid = String(initProfile?.fullformUuid)

  const handleSubmitAgentCode = async () => {
    setIsLoading(true)
    const { payload } = await dispatch(
      callLicenseAgent({
        fullFormUuid: fullFormUuid,
        agentCode: formData?.agentCode,
      })
    )
    setIsLoading(false)
    if (payload?.status && payload?.status !== ApiStatus.ERROR) {
      dispatch(
        openCustomDialog({
          type: DIALOG_TYPE_STRING.SUCCESS,
          title: 'สำเร็จ',
          message: 'บันทึกรหัสตัวแทนเรียบร้อยแล้ว',
          handleOK: () => {
            window.location.reload()
          },
        })
      )
    }
  }
  return (
    <>
      <Stack gap={2}>
        <CustomTextfield
          required
          label="รหัสตัวแทน"
          crud="agentCode"
          placeholder="เลขที่ใบอนุญาต"
          maxLength={14}
          valueType="number"
          fullWidth
        />

        <Stack direction="row" spacing={1.5} marginLeft="auto" paddingY={1}>
          <Button
            variant="outlined"
            onClick={() => dispatch(closeCustomDialog())}
          >
            ยกเลิก
          </Button>
          <CustomLoadingButton
            loading={isLoading}
            disabled={_.size(formData?.agentCode) !== 14}
            onClick={handleSubmitAgentCode}
          >
            บันทึก
          </CustomLoadingButton>
        </Stack>
      </Stack>
    </>
  )
}
