import Home from '../pages/home'
import Login from '../pages/login'

import { RejecteAfterInterviewListPage } from '../pages/master-data/reject-after-interview/list'
import { RejecteAfterInterviewFormPage } from '../pages/master-data/reject-after-interview/form/[uuid]'

import { RejectAppointmentListPage } from '../pages/master-data/reject-appointment/list'
import { RejectAppointmentFormPage } from '../pages/master-data/reject-appointment/form/[uuid]'

import KnowUsFromChannelListPage from '../pages/master-data/know-us-from-channel/list'
import KnowUsFromChannelFormPage from '../pages/master-data/know-us-from-channel/form/[uuid]'

import SocialSubSourceListPage from '../pages/master-data/social-sub-source/list'
import SocialSubSourceFormPage from '../pages/master-data/social-sub-source/form/[uuid]'

import OfflineSubSourceListPage from '../pages/master-data/offline-sub-source/list'
import OfflineSubSourceFormPage from '../pages/master-data/offline-sub-source/form/[uuid]'

import ReasonRequestMemoListPage from '../pages/master-data/reason-request-memo/list'
import ReasonRequestMemoFormPage from '../pages/master-data/reason-request-memo/form/[uuid]'

import { EventSubSourceListPage } from '../pages/master-data/event-sub-source/list'
import { EventSubSourceFormPage } from '../pages/master-data/event-sub-source/form/[uuid]'

import { ReasonsDropProspectByDCListPage } from '../pages/master-data/reasons-drop-prospect-by-dc/list'
import { ReasonsDropProspectByDCFormPage } from '../pages/master-data/reasons-drop-prospect-by-dc/form/[uuid]'

import { RamProfileListPage } from '../pages/master-data/ram-profile/list'
import { RamProfileFormPage } from '../pages/master-data/ram-profile/form/[uuid]'

import { SRamProfileListPage } from '../pages/master-data/sram-profile/list'
import { SRamProfileFormPage } from '../pages/master-data/sram-profile/form/[uuid]'

import { CADTOProfileListPage } from '../pages/master-data/cadto-profile/list'
import { CADTOProfileFormPage } from '../pages/master-data/cadto-profile/form/[uuid]'

import { CDOProfileListPage } from '../pages/master-data/cdo-profile/list'
import { CDOProfileFormPage } from '../pages/master-data/cdo-profile/form/[uuid]'

import ProspectList from '../pages/prospect/list'
import CandidateImportData from '../pages/prospect/import/import'

import { ManagerQueueListPage } from '../pages/assign-rule/manager-queue/list'

import NearbyAreaFormPage from '../pages/assign-rule/nearby-area'

import ImportManagerPage from '../pages/manager/import-manager'
import ProspectDetailPage from '../pages/prospect/detail/[uuid]'
import ImportCandidateListPage from '../pages/prospect/import'

import StaffProfileListPage from '../pages/manage/staff-profile/list'
import { StaffProfileFormPage } from '../pages/manage/staff-profile/form/[uuid]'

import PermissionList from '../pages/manage/permission/list'
import AddPermission from '../pages/manage/permission/form'
import ViewPermission from '../pages/manage/permission/[id]'
import EditPermission from '../pages/manage/permission/edit/[id]'

import DCAdminDashboard from '../pages/dc-admin/dashboard'
import DCAdminList from '../pages/dc-admin/list'
import {
  permissionAgencyAdmin,
  permissionDCAdmin,
  permissionSuperAdmin,
} from '../constants/permission'

import NotificationListPage from '../pages/notification/list'

import ApplicantDetails from '../pages/dc-admin/applicant-details'
import Error404 from '../pages/error/404'

interface Route {
  path: string
  component: React.FC
  exact?: boolean
  title?: string
  permission?: any
}

type RouteList = Route[]

const routes: RouteList = [
  { path: '/home', component: Home },
  { path: '/', component: Login },

  { path: '/reject-after-interview', component: RejecteAfterInterviewListPage },
  {
    path: '/reject-after-interview/form',
    component: RejecteAfterInterviewFormPage,
  },
  {
    path: '/reject-after-interview/form/:uuid',
    component: RejecteAfterInterviewFormPage,
  },

  { path: '/reject-appointment', component: RejectAppointmentListPage },
  { path: '/reject-appointment/form', component: RejectAppointmentFormPage },
  {
    path: '/reject-appointment/form/:uuid',
    component: RejectAppointmentFormPage,
  },

  //Master Data
  {
    path: '/know-us-from-channel',
    component: KnowUsFromChannelListPage,
    permission: permissionSuperAdmin,
  },
  {
    path: '/know-us-from-channel/form',
    component: KnowUsFromChannelFormPage,
    permission: permissionSuperAdmin,
  },
  {
    path: '/know-us-from-channel/form/:uuid',
    component: KnowUsFromChannelFormPage,
    permission: permissionSuperAdmin,
  },

  {
    path: '/social-sub-source',
    component: SocialSubSourceListPage,
    permission: permissionSuperAdmin,
  },
  {
    path: '/social-sub-source/form',
    component: SocialSubSourceFormPage,
    permission: permissionSuperAdmin,
  },
  {
    path: '/social-sub-source/form/:uuid',
    component: SocialSubSourceFormPage,
    permission: permissionSuperAdmin,
  },

  {
    path: '/offline-sub-source',
    component: OfflineSubSourceListPage,
    permission: permissionSuperAdmin,
  },
  {
    path: '/offline-sub-source/form',
    component: OfflineSubSourceFormPage,
    permission: permissionSuperAdmin,
  },
  {
    path: '/offline-sub-source/form/:uuid',
    component: OfflineSubSourceFormPage,
    permission: permissionSuperAdmin,
  },

  {
    path: '/reason-request-memo',
    component: ReasonRequestMemoListPage,
    permission: permissionSuperAdmin,
  },
  {
    path: '/reason-request-memo/form',
    component: ReasonRequestMemoFormPage,
    permission: permissionSuperAdmin,
  },
  {
    path: '/reason-request-memo/form/:uuid',
    component: ReasonRequestMemoFormPage,
    permission: permissionSuperAdmin,
  },

  {
    path: '/event-sub-source',
    component: EventSubSourceListPage,
    permission: permissionSuperAdmin,
  },
  {
    path: '/event-sub-source/form',
    component: EventSubSourceFormPage,
    permission: permissionSuperAdmin,
  },
  {
    path: '/event-sub-source/form/:uuid',
    component: EventSubSourceFormPage,
    permission: permissionSuperAdmin,
  },

  {
    path: '/reasons-drop-prospect-by-dc',
    component: ReasonsDropProspectByDCListPage,
    permission: permissionSuperAdmin,
  },
  {
    path: '/reasons-drop-prospect-by-dc/form',
    component: ReasonsDropProspectByDCFormPage,
    permission: permissionSuperAdmin,
  },
  {
    path: '/reasons-drop-prospect-by-dc/form/:uuid',
    component: ReasonsDropProspectByDCFormPage,
    permission: permissionSuperAdmin,
  },

  {
    path: '/ram-profile',
    component: RamProfileListPage,
    permission: permissionSuperAdmin,
  },
  {
    path: '/ram-profile/form',
    component: RamProfileFormPage,
    permission: permissionSuperAdmin,
  },
  {
    path: '/ram-profile/form/:uuid',
    component: RamProfileFormPage,
    permission: permissionSuperAdmin,
  },

  {
    path: '/sram-profile',
    component: SRamProfileListPage,
    permission: permissionSuperAdmin,
  },
  {
    path: '/sram-profile/form',
    component: SRamProfileFormPage,
    permission: permissionSuperAdmin,
  },
  {
    path: '/sram-profile/form/:uuid',
    component: SRamProfileFormPage,
    permission: permissionSuperAdmin,
  },

  {
    path: '/cadto-profile',
    component: CADTOProfileListPage,
    permission: permissionSuperAdmin,
  },
  {
    path: '/cadto-profile/form',
    component: CADTOProfileFormPage,
    permission: permissionSuperAdmin,
  },
  {
    path: '/cadto-profile/form/:uuid',
    component: CADTOProfileFormPage,
    permission: permissionSuperAdmin,
  },

  {
    path: '/cdo-profile',
    component: CDOProfileListPage,
    permission: permissionSuperAdmin,
  },
  {
    path: '/cdo-profile/form',
    component: CDOProfileFormPage,
    permission: permissionSuperAdmin,
  },
  {
    path: '/cdo-profile/form/:uuid',
    component: CDOProfileFormPage,
    permission: permissionSuperAdmin,
  },

  {
    path: '/manager-queue',
    component: ManagerQueueListPage,
    permission: permissionSuperAdmin,
  },
  {
    path: '/manager/import-manager',
    component: ImportManagerPage,
    permission: permissionSuperAdmin,
  },

  {
    path: '/nearby-area',
    component: NearbyAreaFormPage,
    permission: permissionSuperAdmin,
  },

  //prospect menu
  {
    path: '/prospect',
    component: ProspectList,
    permission: permissionAgencyAdmin,
  },
  {
    path: '/prospect/detail/:uuid',
    component: ProspectDetailPage,
    permission: permissionAgencyAdmin,
  },
  // candidate
  {
    path: '/candidate',
    component: ImportCandidateListPage,
    permission: permissionAgencyAdmin,
  },
  {
    path: '/candidate/import',
    component: CandidateImportData,
    permission: permissionAgencyAdmin,
  },

  // manage
  {
    path: '/manage/staff-profile',
    component: StaffProfileListPage,
    permission: permissionSuperAdmin,
  },
  {
    path: '/manage/staff-profile/form',
    component: StaffProfileFormPage,
    permission: permissionSuperAdmin,
  },
  {
    path: '/manage/staff-profile/form/:uuid',
    component: StaffProfileFormPage,
    permission: permissionSuperAdmin,
  },

  {
    path: '/manage/permission',
    component: PermissionList,
    permission: permissionSuperAdmin,
  },
  {
    path: '/manage/permission/form',
    component: AddPermission,
    permission: permissionSuperAdmin,
  },
  {
    path: '/manage/permission/:id',
    component: ViewPermission,
    permission: permissionSuperAdmin,
  },
  {
    path: '/manage/permission/edit/:id',
    component: EditPermission,
    permission: permissionSuperAdmin,
  },
  // DC Admin
  {
    path: '/dc-admin/applicant-details/:prospectId',
    component: ApplicantDetails,
  },
  {
    path: '/application-verification/dashboard',
    component: DCAdminDashboard,
    permission: permissionDCAdmin,
  },
  {
    path: '/application-verification/list',
    component: DCAdminList,
    permission: permissionDCAdmin,
  },
  { path: '/notifications', component: NotificationListPage },
  {
    path: '*',
    component: Error404,
  },
]

export default routes
