import Box from '@mui/material/Box'
import Chip from '@mui/material/Chip'
import { DC_STATUS, DC_STATUS_DATA } from '../../../../../../constants/dc-admin'
import { getQueryParams } from '../../../../../../utils/getQueryParams'
import useHistory from '../../../../../../utils/useHistory'
import { statusIcon } from '../model/statusIcon'
import { crudConfig } from '../../../crudConfig'
import Typography from '@mui/material/Typography'

const ChipFilterContent = () => {
  const status = getQueryParams('status')
  const history = useHistory()
  return (
    <Box sx={{ display: 'flex', gap: 1, mt: 3 }}>
      {DC_STATUS.map((item: DC_STATUS_DATA, key: number) => {
        const isSelected = status === item.name
        const img = `${statusIcon[item.name]}${isSelected ? '' : '-primary'}`
        return (
          <Chip
            key={key}
            color="primary"
            icon={
              <img
                src={`/icons/${img}.svg`}
                style={{ width: 19, marginLeft: 8 }}
              />
            }
            label={<Typography variant="body2">{item.label}</Typography>}
            variant={isSelected ? 'filled' : 'outlined'}
            onClick={() => {
              const path = isSelected
                ? crudConfig.modulePath
                : `${crudConfig.modulePath}?status=${item.name}`
              window.location.href = `${path}`
            }}
          />
        )
      })}
    </Box>
  )
}

export default ChipFilterContent
