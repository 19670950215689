import { ApiStatus } from '../../../../../constants/status'
import { AppDispatch } from '../../../../../redux/store'
import { setHistoryProcess } from '../../slices'
import { callHistoryProcess } from '../services/thunk'

export const getHistoryProcess =
  (fullFormUuid: string) => async (dispatch: AppDispatch) => {
    const { payload } = await dispatch(
      callHistoryProcess({
        fullFormUuid: fullFormUuid,
        limit: 100,
        page: 1,
        order: 'ASC',
        sort: 'id',
      })
    )
    if (payload?.status !== ApiStatus.ERROR) {
      dispatch(setHistoryProcess(payload.result))
    }
  }
