import { Box, Typography } from '@mui/material'
import {
  closeCustomDialog,
  openCustomDialog,
} from '../../../../components/CustomDialog/slices'
import { dispatch, useSelector } from '../../../../redux/store'
import CustomButton, {
  CustomLoadingButton,
} from '../../../../components/Input/CustomButton'
import { UploadMultiReturnPath } from '../../../../components/FullUpload'
import {
  selectCrudFormData,
  selectInitialProfile,
} from '../../../../redux/selector'
import _ from 'lodash'
import { callCCMResult } from './services/thunk'
import { ApiStatus } from '../../../../constants/status'
import { DIALOG_TYPE_STRING } from '../../../../components/dialog/constants'
import { useState } from 'react'
import { getTitleNotOwner } from '../handler/getTitleNotOwner'

export const CCMResultDecision = () => {
  const initProfile = useSelector(selectInitialProfile)
  const fullFormUuid = String(initProfile?.fullformUuid)
  const formData = useSelector(selectCrudFormData)
  const { considerationCCM } = formData
  const metaIds = _.map(considerationCCM?.attachments ?? [], 'id')
  const [isLoading, setIsLoading] = useState(false)

  const title = getTitleNotOwner(initProfile, '')

  const handleSubmitResultCCM = async () => {
    setIsLoading(true)
    const { payload } = await dispatch(
      callCCMResult({
        fullFormUuid: fullFormUuid,
        metaId: metaIds,
      })
    )
    if (payload.status !== ApiStatus.ERROR) {
      dispatch(
        openCustomDialog({
          type: DIALOG_TYPE_STRING.SUCCESS,
          title: 'สำเร็จ',
          message: 'ยืนยันผลการตรวจสอบเรียบร้อยแล้ว',
          handleOK: () => {
            window.location.reload()
          },
        })
      )
    }

    setIsLoading(false)
  }
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          mb: 2,
          justifyContent: 'space-between',
          alignItems: 'center',
          pr: 2,
        }}
      >
        <Typography variant="h6" sx={{ width: '100%' }}>
          {title} ยืนยันผลการตรวจสอบ
        </Typography>
      </Box>

      <Box
        sx={{
          maxHeight: '65vh',
          overflow: 'auto',
          scrollbarWidth: 'thin',
          pr: 3,
        }}
      >
        <UploadMultiReturnPath
          name={'considerationCCM.attachments'}
          label={'แนบเอกสารผลการตรวจสอบจาก CCM *'}
          fullFormUuid={fullFormUuid}
          fieldName={'considerationCCM'}
          maxFile={3}
          info={'สูงสุดไม่เกิน 3 รายการ'}
        />
      </Box>

      <Box
        sx={{ display: 'flex', justifyContent: 'end', gap: 1, mt: 3, pr: 3 }}
      >
        <CustomButton
          sx={{ borderRadius: 1 }}
          variant="outlined"
          color="info"
          onClick={() => dispatch(closeCustomDialog())}
        >
          ยกเลิก
        </CustomButton>
        <CustomLoadingButton
          sx={{ borderRadius: 1 }}
          disabled={_.size(metaIds) == 0}
          loading={isLoading}
          onClick={() => handleSubmitResultCCM()}
        >
          ยืนยัน
        </CustomLoadingButton>
      </Box>
    </>
  )
}
