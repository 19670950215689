import React, { useCallback, useState } from 'react'
import { FileRejection, useDropzone } from 'react-dropzone'
import {
  Avatar,
  Box,
  Grid,
  IconButton,
  LinearProgress,
  Stack,
  Typography,
} from '@mui/material'
import { isMobile, isTablet } from 'react-device-detect'
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined'
import CameraAltIcon from '@mui/icons-material/CameraAlt'
import _ from 'lodash'
import CustomButton from '../Input/CustomButton'
import { PUBLIC_URL } from '../../constants/webMeta'
import { handleMaxFileSize } from './handler/handleMaxFileSize'
import CustomImage from '../Layout/CustomImage'
import { FileDbData, UploadMultiProps } from './types/upload'
import { dispatch } from '../../redux/store'
import { handleUploadFile } from './handler/handleUploadFile'
import { CustomCard } from '../Layout/CustomCard'
import { convertToMB } from '../CropperDialog/handler/handleReadFile'
import ImageIcon from '@mui/icons-material/Image'
import PictureAsPdfRoundedIcon from '@mui/icons-material/PictureAsPdfRounded'
import { getStorageUrl } from './handler/getStorageUrl'
import { ApiStatus } from '../../constants/status'
import { setFieldValue } from '../CRUD/slices'
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { useInputField } from '../CRUD/handler/useInputField'
export const UploadMultiReturnPath = (props: UploadMultiProps) => {
  const {
    name = 'files',
    label,
    textError = '',
    maxFile = 3,
    fieldName = 'file',
    fullFormUuid = '',
    isView = false,
    defFiles = [],
    onUploadComplete,
    onUploading,
  } = props

  const [uploading, setUploading] = useState<File[]>([])
  const [uploadSuccess, setUploadSuccess] = useState<FileDbData[]>([])
  const valueData = useInputField(name)
  const values: FileDbData[] = _.isArray(valueData.value) ? valueData.value : []

  const uploadFiles = async (acceptedFiles: File[]) => {
    const fileUploads = [...acceptedFiles]
    setUploading(fileUploads)
    onUploading && onUploading(fileUploads)

    for (const file of acceptedFiles) {
      try {
        if (_.size(values) + _.size(uploadSuccess) < maxFile)
          await uploadFile(file)
      } catch (error) {
        console.log('error>>', error)
      }
    }

    const newArr = [...values, ...uploadSuccess]
    dispatch(
      setFieldValue({
        key: `formData.${name}`,
        value: newArr,
      })
    )
    setUploadSuccess([])
    setUploading([])
  }
  const uploadFile = async (file: File) => {
    const payload = await dispatch(
      handleUploadFile({
        file: file,
        fullFormUuid: fullFormUuid,
        path: 'upload',
        data: {
          fieldName: fieldName ? fieldName : name,
          mimeType: file.type,
        },
      })
    )

    if (payload.status === ApiStatus.SUCCESS) {
      const newArrSuccess = uploadSuccess
      newArrSuccess.push(payload.result[0])
      setUploadSuccess(newArrSuccess)
      onUploadComplete && onUploadComplete(newArrSuccess)
    }
  }
  const handleDelete = (id: number) => {
    const newArr = values.filter((item) => item.id !== id)
    dispatch(
      setFieldValue({
        key: `formData.${name}`,
        value: newArr,
      })
    )
  }
  return (
    <>
      {label && (
        <Typography variant="body2" color="text.secondary">
          {label}
        </Typography>
      )}
      {maxFile && _.size(values) < maxFile && !isView && (
        <Box sx={{ mb: 1 }}>
          <UploadMulti
            isReturnPath
            {...props}
            onFileUpload={async (e) => await uploadFiles(e)}
          />
        </Box>
      )}

      {textError !== '' && (
        <Typography sx={{ mt: 1 }} variant="body2" color={'error'}>
          {textError}
        </Typography>
      )}
      {uploading.map((file, index) => (
        <CustomCard key={index} sx={{ p: 1, mb: 1 }}>
          <Box sx={{ display: 'flex', gap: 2 }}>
            <IconButton>
              <ImageIcon />
            </IconButton>
            <Box>
              <Typography>{file.name}</Typography>
              <Typography variant="body2">{convertToMB(file.size)}</Typography>
            </Box>
          </Box>
          <Box sx={{ width: '100%', mt: 1 }}>
            <LinearProgress />
          </Box>
        </CustomCard>
      ))}
      {[...defFiles, ...values].map((file, index) => (
        <CustomCard key={index} sx={{ p: 1, mb: 1 }}>
          <Box sx={{ display: 'flex', gap: 2 }}>
            <Box sx={{ width: '100%', display: 'flex', gap: 2 }}>
              {file.type === 'image/png' || file.type === 'image/jpeg' ? (
                <Avatar src={getStorageUrl(file.url)} variant="square" />
              ) : (
                <PictureAsPdfRoundedIcon fontSize={'large'} color="error" />
              )}

              <Box>
                <Typography>{file.originalFileName}</Typography>
                <Typography variant="body2">
                  {convertToMB(file.size)}
                </Typography>
              </Box>
            </Box>
            {!isView && (
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <IconButton onClick={() => handleDelete(file.id)}>
                  <DeleteRoundedIcon fontSize={'small'} />
                </IconButton>
              </Box>
            )}
          </Box>
        </CustomCard>
      ))}
    </>
  )
}
const UploadMulti = (props: UploadMultiProps) => {
  const { onFileUpload, isError, textError = '', info } = props
  const [fileErrors, setFileErrors] = useState<string[]>([])
  const [acceptCamera, setAcceptCamera] = useState<boolean>(false)

  const onDrop = useCallback(
    async (acceptedFiles: File[], rejectedFiles: FileRejection[]) => {
      if (rejectedFiles.length > 0) {
        setFileErrors(['errors'])
      } else {
        setFileErrors([])
        setAcceptCamera(false)
        if (acceptedFiles.length > 0)
          onFileUpload && onFileUpload(acceptedFiles)
      }
    },
    [onFileUpload]
  )
  const getAcceptType = (typeFixed: string[]) => {
    const acceptType = {
      jpg: { 'image/jpeg': ['.jpeg', '.jpg'] },
      png: { 'image/png': ['.png'] },
      pdf: { 'application/pdf': ['.pdf'] },
    }

    if (_.size(typeFixed) === 0)
      return { ...acceptType.jpg, ...acceptType.png, ...acceptType.pdf }

    return _.reduce(
      typeFixed,
      (acc, type) => {
        const lowerType = _.toLower(type)
        if (_.get(acceptType, lowerType)) {
          return { ...acc, ...(_.get(acceptType, lowerType) as any) }
        }
        return acc
      },
      {}
    )
  }
  const onDragOver = () => {
    setFileErrors([])
    setAcceptCamera(false)
  }
  const { getRootProps, getInputProps, isDragActive, isDragReject } =
    useDropzone({
      onDrop,
      maxSize: handleMaxFileSize({
        size: 5,
        sizeType: 'MB',
      }),
      accept: getAcceptType(_.get(props, 'typeFixed', [])),
      onDragOver,
    })

  return (
    <>
      <Box
        {...getRootProps()}
        sx={{
          border:
            isError ||
            textError !== '' ||
            isDragReject ||
            _.size(fileErrors) > 0
              ? '2px dashed red'
              : '2px dashed #CCCCCC',
          px: 1,
          py: 3,
          textAlign: 'center',
          cursor: 'pointer',
          borderRadius: 2,
          backgroundColor: isDragActive ? '#f0f8ff' : '#fafafa',
          transition: 'background-color 0.2s ease-in-out',
          '&:hover': {
            backgroundColor: '#f0f8ff',
          },
        }}
      >
        {acceptCamera ? (
          <input
            {...getInputProps({ capture: 'environment', accept: 'image/*' })}
          />
        ) : (
          <input {...getInputProps()} />
        )}

        <Grid container spacing={1}>
          <Grid item xs={12} sm={7}>
            <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
              <CustomImage
                src={`${PUBLIC_URL}/images/illustrate_upload-dc-admin.svg`}
                width="80px"
                height="72px"
              />
              <Stack justifyContent="center" alignItems="center">
                <Typography
                  sx={{ display: { xs: 'none', sm: 'block' } }}
                  variant="body1"
                  color={'primary.main'}
                  fontWeight={700}
                >
                  วางไฟล์ที่นี่
                </Typography>

                <Typography variant="body2" color={'text.secondary'}>
                  {props.typeFixed
                    ? props.typeFixed.join(',')
                    : 'JPG, JPEG, PNG, PDF'}{' '}
                  ที่มีขนาดไฟล์ไม่เกิน 5 MB
                </Typography>
              </Stack>

              <Typography
                sx={{ display: { xs: 'none', sm: 'block' } }}
                variant="body1"
                fontWeight={700}
              >
                หรือ
              </Typography>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sm={5}
            sx={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: { xs: 'center', sm: 'left' },
              gap: 1,
            }}
          >
            <CustomButton
              variant="text"
              sx={{ px: { xs: 1, sm: 3 } }}
              onClick={() => setAcceptCamera(false)}
            >
              <FileUploadOutlinedIcon sx={{ mr: 1 }} />
              <Typography variant="body2">Upload file</Typography>
            </CustomButton>
            {(isMobile || isTablet) && (
              <CustomButton
                variant="text"
                sx={{ px: { xs: 1, sm: 3 } }}
                onClick={() => setAcceptCamera(true)}
              >
                <CameraAltIcon sx={{ mr: 1 }} />
                <Typography variant="body2">Camera</Typography>
              </CustomButton>
            )}
          </Grid>
        </Grid>
      </Box>
      {_.size(fileErrors) > 0 && (
        <Typography sx={{ mt: 1 }} variant="body2" color={'error'}>
          รูปแบบไฟล์ต้อง{' '}
          {props.typeFixed ? props.typeFixed.join(',') : 'JPG, JPEG, PNG, PDF'}{' '}
          ที่มีขนาดไฟล์ไม่เกิน 5 MB
        </Typography>
      )}
      {info && (
        <Box sx={{ display: 'flex', gap: 1, mt: 1, alignItems: 'center' }}>
          <InfoOutlinedIcon sx={{ color: 'primary.light' }} />
          <Typography variant={'body2'} color={'primary.light'}>
            {info}
          </Typography>
        </Box>
      )}
    </>
  )
}
export default UploadMulti
