import { store } from '../../../../../../redux/store'
import _ from 'lodash'
import { getQueryParams } from '../../../../../../utils/getQueryParams'
import { DC_STATUS } from '../../../../../../constants/dc-admin'
import { setHandleChange } from '../../../../../../components/Table/redux'

export const mutateFilterPropToBody = () => {
  const { filterState } = store.getState().table
  const status = getQueryParams('status')
  const menu = _.find(DC_STATUS, { name: status })

  const paramSubStatus = getQueryParams('subStatus')
  let subStatus: string[] = []
  if (!_.isEmpty(paramSubStatus)) {
    const arrSubStatus = paramSubStatus.split(',')
    subStatus = _.size(arrSubStatus) > 0 ? arrSubStatus : []
    const newFilter = { ...filterState, subStatus: subStatus }
    store.dispatch(
      setHandleChange({
        key: 'filterState',
        value: newFilter,
      })
    )
  }

  return {
    codeId: _.get(filterState, 'codeId', ''),
    name: _.get(filterState, 'name', ''),
    contractor: _.get(filterState, 'contractor', ''),
    email: _.get(filterState, 'email', ''),
    idCardNo: _.get(filterState, 'idCardNo', ''),
    registerStartDate: _.get(filterState, 'startDate', ''),
    registerEndDate: _.get(filterState, 'endDate', ''),
    status: _.size(menu?.status) > 0 ? menu?.status : [],
    subStatus: subStatus,
    optionSearch: filterState.optionSearch ? filterState.optionSearch : [],
  }
}
